@charset "UTF-8";
/*-------------------------------------------------------------

  #  Template Name: Sheolly - SEO & Digital Marketing Agency Bootstrap 5 Template
  #  Author   Name: 
  #  Version: 1.0

-------------------------------------------------------------
    
  #   CSS INDEX
========================================================
	
   [ 01. Bootstrap CSS ]

   [ 02. Template default CSS ]

   [ 03. header CSS ]

   [ 04. header top CSS ]

   [ 05. main menu CSS ]

   [ 06. sub menu CSS ]

   [ 07. hero section CSS ]

   [ 08. brand logo CSS ]

   [ 09. service card CSS ]

   [ 10. working process CSS ]

   [ 11. about us CSS ]

   [ 12. case studies CSS ]

   [ 13. team CSS ]

   [ 14. faq CSS ]

   [ 15. accordion CSS ]

   [ 16. testimonial CSS ]

   [ 17. blog CSS ]

   [ 18. footer CSS ]

   [ 19. bread crumb CSS ]

   [ 20. service page CSS ]

   [ 21. case details CSS ]

   [ 22. comment form CSS ]

   [ 23. blog details CSS ]

   [ 24. contact form CSS ]

   [ 25. page not found CSS ]


   [ The End ]


--------------------------------------------*/
/*--------------------------------------
/*  [ 01. Bootstrap CSS ]
----------------------------------------*/
/*!
 * Bootstrap v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Ruda:wght@400;500;600;700;800;900&display=swap");
:root {
  --bs-blue: #02126a;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #fc7418;
  --bs-green: #32b618;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #fbfbfb;
  --bs-gray-dark: #f8f8f8;
  --bs-primary: #02126a;
  --bs-secondary: #fc7418;
  --bs-success: #32b618;
  --bs-info: #0dcaf0;
  --bs-warning: #fc7418;
  --bs-danger: #dc3545;
  --bs-light: #f8f8f8;
  --bs-dark: #32383e;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: "Ruda", sans-serif;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.8;
  color: #767988;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.35938rem + 1.3125vw);
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.34375rem;
  }
}

h2, .h2 {
  font-size: calc(1.3125rem + 0.75vw);
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.875rem;
  }
}

h3, .h3 {
  font-size: calc(1.28906rem + 0.46875vw);
}

@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.64063rem;
  }
}

h4, .h4 {
  font-size: calc(1.26563rem + 0.1875vw);
}

@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.40625rem;
  }
}

h5, .h5 {
  font-size: 1.17188rem;
}

h6, .h6 {
  font-size: 0.9375rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #02126a;
  text-decoration: underline;
}

a:hover {
  color: #020e55;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #fbfbfb;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.17188rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.17188rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #fbfbfb;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #fbfbfb;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.9375rem);
  padding-left: var(--bs-gutter-x, 0.9375rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1170px;
  }
}

.row {
  --bs-gutter-x: 1.875rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.3125rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.3125rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.625rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.625rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 0.9375rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 0.9375rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.25rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.25rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 1.625rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 1.625rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 1.875rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 1.875rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 2.1875rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 2.1875rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 2.5rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 2.5rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 2.8125rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 2.8125rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 3.125rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 3.125rem;
}

@media (min-width: 576px) {
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 1.625rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 1.625rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 3.125rem;
  }
}

@media (min-width: 768px) {
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 1.625rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 1.625rem;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 3.125rem;
  }
}

@media (min-width: 992px) {
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 1.625rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 1.625rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 3.125rem;
  }
}

@media (min-width: 1200px) {
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 1.625rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 1.625rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 3.125rem;
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #767988;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #767988;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #767988;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #767988;
  vertical-align: top;
  border-color: #273581;
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #ccd0e1;
  --bs-table-striped-bg: #c2c6d6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8bbcb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bdc0d0;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #b8bbcb;
}

.table-secondary {
  --bs-table-bg: #fee3d1;
  --bs-table-striped-bg: #f1d8c7;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e5ccbc;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ebd2c1;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e5ccbc;
}

.table-success {
  --bs-table-bg: #d6f0d1;
  --bs-table-striped-bg: #cbe4c7;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c1d8bc;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c6dec1;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c1d8bc;
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3;
}

.table-warning {
  --bs-table-bg: #fee3d1;
  --bs-table-striped-bg: #f1d8c7;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e5ccbc;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ebd2c1;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e5ccbc;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4;
}

.table-light {
  --bs-table-bg: #f8f8f8;
  --bs-table-striped-bg: #ececec;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfdfdf;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e5e5;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfdfdf;
}

.table-dark {
  --bs-table-bg: #32383e;
  --bs-table-striped-bg: #3c4248;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #474c51;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #41474c;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #474c51;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.8;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.17188rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.82031rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #fbfbfb;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.8;
  color: #767988;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #767988;
  background-color: #fff;
  border-color: #8189b5;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(2, 18, 106, 0.25);
}

.form-control::-webkit-date-and-time-value {
  height: 1.8em;
}

.form-control::placeholder {
  color: #a6a6a6;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #767988;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #767988;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.8;
  color: #767988;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.8em + (0.5rem + 2px));
  padding: 0.25rem 0.5rem;
  font-size: 0.82031rem;
  border-radius: 0.2rem;
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.8em + (1rem + 2px));
  padding: 0.5rem 1rem;
  font-size: 1.17188rem;
  border-radius: 0.3rem;
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.8em + (0.75rem + 2px));
}

textarea.form-control-sm {
  min-height: calc(1.8em + (0.5rem + 2px));
}

textarea.form-control-lg {
  min-height: calc(1.8em + (1rem + 2px));
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  height: 1.8em;
  border-radius: 4px;
}

.form-control-color::-webkit-color-swatch {
  height: 1.8em;
  border-radius: 4px;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.8;
  color: #767988;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23f8f8f8' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #8189b5;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(2, 18, 106, 0.25);
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #767988;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.82031rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.17188rem;
}

.form-check {
  display: block;
  min-height: 1.6875rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.4em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #8189b5;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(2, 18, 106, 0.25);
}

.form-check-input:checked {
  background-color: #02126a;
  border-color: #02126a;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #02126a;
  border-color: #02126a;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238189b5'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(2, 18, 106, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(2, 18, 106, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #02126a;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b3b8d2;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #02126a;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #b3b8d2;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control {
  padding: 1rem 0.75rem;
}

.form-floating > .form-control::placeholder {
  color: transparent;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.8;
  color: #767988;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.17188rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.82031rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #32b618;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.82031rem;
  color: #000;
  background-color: rgba(50, 182, 24, 0.9);
  border-radius: 4px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #32b618;
  padding-right: calc(1.8em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2332b618' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.45em + 0.1875rem) center;
  background-size: calc(0.9em + 0.375rem) calc(0.9em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #32b618;
  box-shadow: 0 0 0 0 rgba(50, 182, 24, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.8em + 0.75rem);
  background-position: top calc(0.45em + 0.1875rem) right calc(0.45em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #32b618;
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23f8f8f8' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2332b618' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.9em + 0.375rem) calc(0.9em + 0.375rem);
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #32b618;
  box-shadow: 0 0 0 0 rgba(50, 182, 24, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #32b618;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #32b618;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0 rgba(50, 182, 24, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #32b618;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated
.input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}

.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus, .was-validated
.input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.82031rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 4px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.8em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.45em + 0.1875rem) center;
  background-size: calc(0.9em + 0.375rem) calc(0.9em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.8em + 0.75rem);
  background-position: top calc(0.45em + 0.1875rem) right calc(0.45em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23f8f8f8' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.9em + 0.375rem) calc(0.9em + 0.375rem);
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated
.input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}

.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .was-validated
.input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 700;
  line-height: 1;
  color: #767988;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 18px 35px;
  font-size: 0.9375rem;
  border-radius: 8px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #767988;
}

.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(2, 18, 106, 0.25);
}

.btn:disabled, .btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #02126a;
  border-color: #02126a;
}

.btn-primary:hover {
  color: #fff;
  background-color: #020f5a;
  border-color: #020e55;
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #020f5a;
  border-color: #020e55;
  box-shadow: 0 0 0 0 rgba(40, 54, 128, 0.5);
}

.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #020e55;
  border-color: #020e50;
}

.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(40, 54, 128, 0.5);
}

.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #02126a;
  border-color: #02126a;
}

.btn-secondary {
  color: #000;
  background-color: #fc7418;
  border-color: #fc7418;
}

.btn-secondary:hover {
  color: #000;
  background-color: #fc893b;
  border-color: #fc822f;
}

.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #000;
  background-color: #fc893b;
  border-color: #fc822f;
  box-shadow: 0 0 0 0 rgba(214, 99, 20, 0.5);
}

.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: #000;
  background-color: #fd9046;
  border-color: #fc822f;
}

.btn-check:checked + .btn-secondary:focus,
.btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(214, 99, 20, 0.5);
}

.btn-secondary:disabled, .btn-secondary.disabled {
  color: #000;
  background-color: #fc7418;
  border-color: #fc7418;
}

.btn-success {
  color: #000;
  background-color: #32b618;
  border-color: #32b618;
}

.btn-success:hover {
  color: #000;
  background-color: #51c13b;
  border-color: #47bd2f;
}

.btn-check:focus + .btn-success, .btn-success:focus {
  color: #000;
  background-color: #51c13b;
  border-color: #47bd2f;
  box-shadow: 0 0 0 0 rgba(43, 155, 20, 0.5);
}

.btn-check:checked + .btn-success,
.btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
.show > .btn-success.dropdown-toggle {
  color: #000;
  background-color: #5bc546;
  border-color: #47bd2f;
}

.btn-check:checked + .btn-success:focus,
.btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(43, 155, 20, 0.5);
}

.btn-success:disabled, .btn-success.disabled {
  color: #000;
  background-color: #32b618;
  border-color: #32b618;
}

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-info:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
}

.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: 0 0 0 0 rgba(11, 172, 204, 0.5);
}

.btn-check:checked + .btn-info,
.btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}

.btn-check:checked + .btn-info:focus,
.btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(11, 172, 204, 0.5);
}

.btn-info:disabled, .btn-info.disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-warning {
  color: #000;
  background-color: #fc7418;
  border-color: #fc7418;
}

.btn-warning:hover {
  color: #000;
  background-color: #fc893b;
  border-color: #fc822f;
}

.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #fc893b;
  border-color: #fc822f;
  box-shadow: 0 0 0 0 rgba(214, 99, 20, 0.5);
}

.btn-check:checked + .btn-warning,
.btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #fd9046;
  border-color: #fc822f;
}

.btn-check:checked + .btn-warning:focus,
.btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(214, 99, 20, 0.5);
}

.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #fc7418;
  border-color: #fc7418;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}

.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: 0 0 0 0 rgba(225, 83, 97, 0.5);
}

.btn-check:checked + .btn-danger,
.btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}

.btn-check:checked + .btn-danger:focus,
.btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(225, 83, 97, 0.5);
}

.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light {
  color: #000;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}

.btn-light:hover {
  color: #000;
  background-color: #f9f9f9;
  border-color: #f9f9f9;
}

.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f9f9f9;
  border-color: #f9f9f9;
  box-shadow: 0 0 0 0 rgba(211, 211, 211, 0.5);
}

.btn-check:checked + .btn-light,
.btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
.show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9f9f9;
  border-color: #f9f9f9;
}

.btn-check:checked + .btn-light:focus,
.btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(211, 211, 211, 0.5);
}

.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}

.btn-dark {
  color: #fff;
  background-color: #32383e;
  border-color: #32383e;
}

.btn-dark:hover {
  color: #fff;
  background-color: #2b3035;
  border-color: #282d32;
}

.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #2b3035;
  border-color: #282d32;
  box-shadow: 0 0 0 0 rgba(81, 86, 91, 0.5);
}

.btn-check:checked + .btn-dark,
.btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #282d32;
  border-color: #262a2f;
}

.btn-check:checked + .btn-dark:focus,
.btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(81, 86, 91, 0.5);
}

.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #32383e;
  border-color: #32383e;
}

.btn-outline-primary {
  color: #02126a;
  border-color: #02126a;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #02126a;
  border-color: #02126a;
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0 rgba(2, 18, 106, 0.5);
}

.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #02126a;
  border-color: #02126a;
}

.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(2, 18, 106, 0.5);
}

.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #02126a;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #fc7418;
  border-color: #fc7418;
}

.btn-outline-secondary:hover {
  color: #000;
  background-color: #fc7418;
  border-color: #fc7418;
}

.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0 rgba(252, 116, 24, 0.5);
}

.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #000;
  background-color: #fc7418;
  border-color: #fc7418;
}

.btn-check:checked + .btn-outline-secondary:focus,
.btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(252, 116, 24, 0.5);
}

.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #fc7418;
  background-color: transparent;
}

.btn-outline-success {
  color: #32b618;
  border-color: #32b618;
}

.btn-outline-success:hover {
  color: #000;
  background-color: #32b618;
  border-color: #32b618;
}

.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0 rgba(50, 182, 24, 0.5);
}

.btn-check:checked + .btn-outline-success,
.btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #000;
  background-color: #32b618;
  border-color: #32b618;
}

.btn-check:checked + .btn-outline-success:focus,
.btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(50, 182, 24, 0.5);
}

.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #32b618;
  background-color: transparent;
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0 rgba(13, 202, 240, 0.5);
}

.btn-check:checked + .btn-outline-info,
.btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-check:checked + .btn-outline-info:focus,
.btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(13, 202, 240, 0.5);
}

.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-warning {
  color: #fc7418;
  border-color: #fc7418;
}

.btn-outline-warning:hover {
  color: #000;
  background-color: #fc7418;
  border-color: #fc7418;
}

.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0 rgba(252, 116, 24, 0.5);
}

.btn-check:checked + .btn-outline-warning,
.btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #fc7418;
  border-color: #fc7418;
}

.btn-check:checked + .btn-outline-warning:focus,
.btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(252, 116, 24, 0.5);
}

.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #fc7418;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5);
}

.btn-check:checked + .btn-outline-danger,
.btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-check:checked + .btn-outline-danger:focus,
.btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5);
}

.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-light {
  color: #f8f8f8;
  border-color: #f8f8f8;
}

.btn-outline-light:hover {
  color: #000;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}

.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0 rgba(248, 248, 248, 0.5);
}

.btn-check:checked + .btn-outline-light,
.btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}

.btn-check:checked + .btn-outline-light:focus,
.btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(248, 248, 248, 0.5);
}

.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #f8f8f8;
  background-color: transparent;
}

.btn-outline-dark {
  color: #32383e;
  border-color: #32383e;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #32383e;
  border-color: #32383e;
}

.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0 rgba(50, 56, 62, 0.5);
}

.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #32383e;
  border-color: #32383e;
}

.btn-check:checked + .btn-outline-dark:focus,
.btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(50, 56, 62, 0.5);
}

.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #32383e;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #02126a;
  text-decoration: underline;
}

.btn-link:hover {
  color: #020e55;
}

.btn-link:disabled, .btn-link.disabled {
  color: #fbfbfb;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 24px 25px;
  font-size: 1rem;
  border-radius: 8px;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.9375rem 1.75rem;
  font-size: 0.9375rem;
  border-radius: 8px;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 0.9375rem;
  color: #767988;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #02126a;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.82031rem;
  color: #fbfbfb;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #f8f8f8;
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}

.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #02126a;
}

.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}

.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 26.25px;
  padding-left: 26.25px;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 1.3125rem;
  padding-left: 1.3125rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 18.75px;
  padding-left: 18.75px;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #02126a;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: #020e55;
}

.nav-link.disabled {
  color: #fbfbfb;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}

.nav-tabs .nav-link.disabled {
  color: #fbfbfb;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 4px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #02126a;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar > .container,
.navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: 0.28906rem;
  padding-bottom: 0.28906rem;
  margin-right: 1rem;
  font-size: 1.17188rem;
  text-decoration: none;
  white-space: nowrap;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.17188rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 8px;
  transition: box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: 3px 3px 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 3px 3px;
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: 3px;
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.card-group > .card {
  margin-bottom: 0.9375rem;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 19px 40px;
  font-size: 0.9375rem;
  color: #02126a;
  text-align: left;
  background-color: #f7faff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: #02105f;
  background-color: #f7faff;
  box-shadow: inset 0 0px 0 rgba(0, 0, 0, 0.125);
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2302105f'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2302126a'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #8189b5;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(2, 18, 106, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #f7faff;
  border: 0px solid rgba(0, 0, 0, 0.125);
}

.accordion-item:first-of-type {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.accordion-body {
  padding: 19px 40px;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #fbfbfb;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}

.breadcrumb-item.active {
  color: #fbfbfb;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #02126a;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: #020e55;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  color: #020e55;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(2, 18, 106, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #02126a;
  border-color: #02126a;
}

.page-item.disabled .page-link {
  color: #fbfbfb;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.17188rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.82031rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 4px;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #010b40;
  background-color: #ccd0e1;
  border-color: #b3b8d2;
}

.alert-primary .alert-link {
  color: #010933;
}

.alert-secondary {
  color: #97460e;
  background-color: #fee3d1;
  border-color: #fed5ba;
}

.alert-secondary .alert-link {
  color: #79380b;
}

.alert-success {
  color: #1e6d0e;
  background-color: #d6f0d1;
  border-color: #c2e9ba;
}

.alert-success .alert-link {
  color: #18570b;
}

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}

.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  color: #97460e;
  background-color: #fee3d1;
  border-color: #fed5ba;
}

.alert-warning .alert-link {
  color: #79380b;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  color: #636363;
  background-color: #fefefe;
  border-color: #fdfdfd;
}

.alert-light .alert-link {
  color: #4f4f4f;
}

.alert-dark {
  color: #1e2225;
  background-color: #d6d7d8;
  border-color: #c2c3c5;
}

.alert-dark .alert-link {
  color: #181b1e;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.70313rem;
  background-color: #e9ecef;
  border-radius: 4px;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #02126a;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 4px;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}

.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f0f0f0;
}

.list-group-item-action:active {
  color: #767988;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #fbfbfb;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #02126a;
  border-color: #02126a;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #010b40;
  background-color: #ccd0e1;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #010b40;
  background-color: #b8bbcb;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #010b40;
  border-color: #010b40;
}

.list-group-item-secondary {
  color: #97460e;
  background-color: #fee3d1;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #97460e;
  background-color: #e5ccbc;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #97460e;
  border-color: #97460e;
}

.list-group-item-success {
  color: #1e6d0e;
  background-color: #d6f0d1;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #1e6d0e;
  background-color: #c1d8bc;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #1e6d0e;
  border-color: #1e6d0e;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #97460e;
  background-color: #fee3d1;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #97460e;
  background-color: #e5ccbc;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #97460e;
  border-color: #97460e;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636363;
  background-color: #fefefe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636363;
  background-color: #e5e5e5;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636363;
  border-color: #636363;
}

.list-group-item-dark {
  color: #1e2225;
  background-color: #d6d7d8;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1e2225;
  background-color: #c1c2c2;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1e2225;
  border-color: #1e2225;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 4px;
  opacity: 0.5;
}

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(2, 18, 106, 0.25);
  opacity: 1;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.toast:not(.showing):not(.show) {
  opacity: 0;
}

.toast.hide {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container > :not(:last-child) {
  margin-bottom: 0.9375rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #fbfbfb;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #273581;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.8;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #273581;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: "Ruda", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.82031rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: "Ruda", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.82031rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}

.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.9375rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #767988;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}

.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.8;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #02126a;
}

.link-primary:hover, .link-primary:focus {
  color: #020e55;
}

.link-secondary {
  color: #fc7418;
}

.link-secondary:hover, .link-secondary:focus {
  color: #fd9046;
}

.link-success {
  color: #32b618;
}

.link-success:hover, .link-success:focus {
  color: #5bc546;
}

.link-info {
  color: #0dcaf0;
}

.link-info:hover, .link-info:focus {
  color: #3dd5f3;
}

.link-warning {
  color: #fc7418;
}

.link-warning:hover, .link-warning:focus {
  color: #fd9046;
}

.link-danger {
  color: #dc3545;
}

.link-danger:hover, .link-danger:focus {
  color: #b02a37;
}

.link-light {
  color: #f8f8f8;
}

.link-light:hover, .link-light:focus {
  color: #f9f9f9;
}

.link-dark {
  color: #32383e;
}

.link-dark:hover, .link-dark:focus {
  color: #282d32;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #273581 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #273581 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #273581 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #273581 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #273581 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #02126a !important;
}

.border-secondary {
  border-color: #fc7418 !important;
}

.border-success {
  border-color: #32b618 !important;
}

.border-info {
  border-color: #0dcaf0 !important;
}

.border-warning {
  border-color: #fc7418 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f8f8 !important;
}

.border-dark {
  border-color: #32383e !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.3125rem !important;
}

.gap-2 {
  gap: 0.625rem !important;
}

.gap-3 {
  gap: 0.9375rem !important;
}

.gap-4 {
  gap: 1.25rem !important;
}

.gap-5 {
  gap: 1.625rem !important;
}

.gap-6 {
  gap: 1.875rem !important;
}

.gap-7 {
  gap: 2.1875rem !important;
}

.gap-8 {
  gap: 2.5rem !important;
}

.gap-9 {
  gap: 2.8125rem !important;
}

.gap-10 {
  gap: 3.125rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.3125rem !important;
}

.m-2 {
  margin: 0.625rem !important;
}

.m-3 {
  margin: 0.9375rem !important;
}

.m-4 {
  margin: 1.25rem !important;
}

.m-5 {
  margin: 1.625rem !important;
}

.m-6 {
  margin: 1.875rem !important;
}

.m-7 {
  margin: 2.1875rem !important;
}

.m-8 {
  margin: 2.5rem !important;
}

.m-9 {
  margin: 2.8125rem !important;
}

.m-10 {
  margin: 3.125rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.3125rem !important;
  margin-left: 0.3125rem !important;
}

.mx-2 {
  margin-right: 0.625rem !important;
  margin-left: 0.625rem !important;
}

.mx-3 {
  margin-right: 0.9375rem !important;
  margin-left: 0.9375rem !important;
}

.mx-4 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}

.mx-5 {
  margin-right: 1.625rem !important;
  margin-left: 1.625rem !important;
}

.mx-6 {
  margin-right: 1.875rem !important;
  margin-left: 1.875rem !important;
}

.mx-7 {
  margin-right: 2.1875rem !important;
  margin-left: 2.1875rem !important;
}

.mx-8 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-9 {
  margin-right: 2.8125rem !important;
  margin-left: 2.8125rem !important;
}

.mx-10 {
  margin-right: 3.125rem !important;
  margin-left: 3.125rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.3125rem !important;
  margin-bottom: 0.3125rem !important;
}

.my-2 {
  margin-top: 0.625rem !important;
  margin-bottom: 0.625rem !important;
}

.my-3 {
  margin-top: 0.9375rem !important;
  margin-bottom: 0.9375rem !important;
}

.my-4 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-5 {
  margin-top: 1.625rem !important;
  margin-bottom: 1.625rem !important;
}

.my-6 {
  margin-top: 1.875rem !important;
  margin-bottom: 1.875rem !important;
}

.my-7 {
  margin-top: 2.1875rem !important;
  margin-bottom: 2.1875rem !important;
}

.my-8 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-9 {
  margin-top: 2.8125rem !important;
  margin-bottom: 2.8125rem !important;
}

.my-10 {
  margin-top: 3.125rem !important;
  margin-bottom: 3.125rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.3125rem !important;
}

.mt-2 {
  margin-top: 0.625rem !important;
}

.mt-3 {
  margin-top: 0.9375rem !important;
}

.mt-4 {
  margin-top: 1.25rem !important;
}

.mt-5 {
  margin-top: 1.625rem !important;
}

.mt-6 {
  margin-top: 1.875rem !important;
}

.mt-7 {
  margin-top: 2.1875rem !important;
}

.mt-8 {
  margin-top: 2.5rem !important;
}

.mt-9 {
  margin-top: 2.8125rem !important;
}

.mt-10 {
  margin-top: 3.125rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.3125rem !important;
}

.me-2 {
  margin-right: 0.625rem !important;
}

.me-3 {
  margin-right: 0.9375rem !important;
}

.me-4 {
  margin-right: 1.25rem !important;
}

.me-5 {
  margin-right: 1.625rem !important;
}

.me-6 {
  margin-right: 1.875rem !important;
}

.me-7 {
  margin-right: 2.1875rem !important;
}

.me-8 {
  margin-right: 2.5rem !important;
}

.me-9 {
  margin-right: 2.8125rem !important;
}

.me-10 {
  margin-right: 3.125rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.3125rem !important;
}

.mb-2 {
  margin-bottom: 0.625rem !important;
}

.mb-3 {
  margin-bottom: 0.9375rem !important;
}

.mb-4 {
  margin-bottom: 1.25rem !important;
}

.mb-5 {
  margin-bottom: 1.625rem !important;
}

.mb-6 {
  margin-bottom: 1.875rem !important;
}

.mb-7 {
  margin-bottom: 2.1875rem !important;
}

.mb-8 {
  margin-bottom: 2.5rem !important;
}

.mb-9 {
  margin-bottom: 2.8125rem !important;
}

.mb-10 {
  margin-bottom: 3.125rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.3125rem !important;
}

.ms-2 {
  margin-left: 0.625rem !important;
}

.ms-3 {
  margin-left: 0.9375rem !important;
}

.ms-4 {
  margin-left: 1.25rem !important;
}

.ms-5 {
  margin-left: 1.625rem !important;
}

.ms-6 {
  margin-left: 1.875rem !important;
}

.ms-7 {
  margin-left: 2.1875rem !important;
}

.ms-8 {
  margin-left: 2.5rem !important;
}

.ms-9 {
  margin-left: 2.8125rem !important;
}

.ms-10 {
  margin-left: 3.125rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.3125rem !important;
}

.m-n2 {
  margin: -0.625rem !important;
}

.m-n3 {
  margin: -0.9375rem !important;
}

.m-n4 {
  margin: -1.25rem !important;
}

.m-n5 {
  margin: -1.625rem !important;
}

.m-n6 {
  margin: -1.875rem !important;
}

.m-n7 {
  margin: -2.1875rem !important;
}

.m-n8 {
  margin: -2.5rem !important;
}

.m-n9 {
  margin: -2.8125rem !important;
}

.m-n10 {
  margin: -3.125rem !important;
}

.mx-n1 {
  margin-right: -0.3125rem !important;
  margin-left: -0.3125rem !important;
}

.mx-n2 {
  margin-right: -0.625rem !important;
  margin-left: -0.625rem !important;
}

.mx-n3 {
  margin-right: -0.9375rem !important;
  margin-left: -0.9375rem !important;
}

.mx-n4 {
  margin-right: -1.25rem !important;
  margin-left: -1.25rem !important;
}

.mx-n5 {
  margin-right: -1.625rem !important;
  margin-left: -1.625rem !important;
}

.mx-n6 {
  margin-right: -1.875rem !important;
  margin-left: -1.875rem !important;
}

.mx-n7 {
  margin-right: -2.1875rem !important;
  margin-left: -2.1875rem !important;
}

.mx-n8 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.mx-n9 {
  margin-right: -2.8125rem !important;
  margin-left: -2.8125rem !important;
}

.mx-n10 {
  margin-right: -3.125rem !important;
  margin-left: -3.125rem !important;
}

.my-n1 {
  margin-top: -0.3125rem !important;
  margin-bottom: -0.3125rem !important;
}

.my-n2 {
  margin-top: -0.625rem !important;
  margin-bottom: -0.625rem !important;
}

.my-n3 {
  margin-top: -0.9375rem !important;
  margin-bottom: -0.9375rem !important;
}

.my-n4 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.my-n5 {
  margin-top: -1.625rem !important;
  margin-bottom: -1.625rem !important;
}

.my-n6 {
  margin-top: -1.875rem !important;
  margin-bottom: -1.875rem !important;
}

.my-n7 {
  margin-top: -2.1875rem !important;
  margin-bottom: -2.1875rem !important;
}

.my-n8 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n9 {
  margin-top: -2.8125rem !important;
  margin-bottom: -2.8125rem !important;
}

.my-n10 {
  margin-top: -3.125rem !important;
  margin-bottom: -3.125rem !important;
}

.mt-n1 {
  margin-top: -0.3125rem !important;
}

.mt-n2 {
  margin-top: -0.625rem !important;
}

.mt-n3 {
  margin-top: -0.9375rem !important;
}

.mt-n4 {
  margin-top: -1.25rem !important;
}

.mt-n5 {
  margin-top: -1.625rem !important;
}

.mt-n6 {
  margin-top: -1.875rem !important;
}

.mt-n7 {
  margin-top: -2.1875rem !important;
}

.mt-n8 {
  margin-top: -2.5rem !important;
}

.mt-n9 {
  margin-top: -2.8125rem !important;
}

.mt-n10 {
  margin-top: -3.125rem !important;
}

.me-n1 {
  margin-right: -0.3125rem !important;
}

.me-n2 {
  margin-right: -0.625rem !important;
}

.me-n3 {
  margin-right: -0.9375rem !important;
}

.me-n4 {
  margin-right: -1.25rem !important;
}

.me-n5 {
  margin-right: -1.625rem !important;
}

.me-n6 {
  margin-right: -1.875rem !important;
}

.me-n7 {
  margin-right: -2.1875rem !important;
}

.me-n8 {
  margin-right: -2.5rem !important;
}

.me-n9 {
  margin-right: -2.8125rem !important;
}

.me-n10 {
  margin-right: -3.125rem !important;
}

.mb-n1 {
  margin-bottom: -0.3125rem !important;
}

.mb-n2 {
  margin-bottom: -0.625rem !important;
}

.mb-n3 {
  margin-bottom: -0.9375rem !important;
}

.mb-n4 {
  margin-bottom: -1.25rem !important;
}

.mb-n5 {
  margin-bottom: -1.625rem !important;
}

.mb-n6 {
  margin-bottom: -1.875rem !important;
}

.mb-n7 {
  margin-bottom: -2.1875rem !important;
}

.mb-n8 {
  margin-bottom: -2.5rem !important;
}

.mb-n9 {
  margin-bottom: -2.8125rem !important;
}

.mb-n10 {
  margin-bottom: -3.125rem !important;
}

.ms-n1 {
  margin-left: -0.3125rem !important;
}

.ms-n2 {
  margin-left: -0.625rem !important;
}

.ms-n3 {
  margin-left: -0.9375rem !important;
}

.ms-n4 {
  margin-left: -1.25rem !important;
}

.ms-n5 {
  margin-left: -1.625rem !important;
}

.ms-n6 {
  margin-left: -1.875rem !important;
}

.ms-n7 {
  margin-left: -2.1875rem !important;
}

.ms-n8 {
  margin-left: -2.5rem !important;
}

.ms-n9 {
  margin-left: -2.8125rem !important;
}

.ms-n10 {
  margin-left: -3.125rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.3125rem !important;
}

.p-2 {
  padding: 0.625rem !important;
}

.p-3 {
  padding: 0.9375rem !important;
}

.p-4 {
  padding: 1.25rem !important;
}

.p-5 {
  padding: 1.625rem !important;
}

.p-6 {
  padding: 1.875rem !important;
}

.p-7 {
  padding: 2.1875rem !important;
}

.p-8 {
  padding: 2.5rem !important;
}

.p-9 {
  padding: 2.8125rem !important;
}

.p-10 {
  padding: 3.125rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.3125rem !important;
  padding-left: 0.3125rem !important;
}

.px-2 {
  padding-right: 0.625rem !important;
  padding-left: 0.625rem !important;
}

.px-3 {
  padding-right: 0.9375rem !important;
  padding-left: 0.9375rem !important;
}

.px-4 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.px-5 {
  padding-right: 1.625rem !important;
  padding-left: 1.625rem !important;
}

.px-6 {
  padding-right: 1.875rem !important;
  padding-left: 1.875rem !important;
}

.px-7 {
  padding-right: 2.1875rem !important;
  padding-left: 2.1875rem !important;
}

.px-8 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-9 {
  padding-right: 2.8125rem !important;
  padding-left: 2.8125rem !important;
}

.px-10 {
  padding-right: 3.125rem !important;
  padding-left: 3.125rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.3125rem !important;
  padding-bottom: 0.3125rem !important;
}

.py-2 {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.py-3 {
  padding-top: 0.9375rem !important;
  padding-bottom: 0.9375rem !important;
}

.py-4 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-5 {
  padding-top: 1.625rem !important;
  padding-bottom: 1.625rem !important;
}

.py-6 {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important;
}

.py-7 {
  padding-top: 2.1875rem !important;
  padding-bottom: 2.1875rem !important;
}

.py-8 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-9 {
  padding-top: 2.8125rem !important;
  padding-bottom: 2.8125rem !important;
}

.py-10 {
  padding-top: 3.125rem !important;
  padding-bottom: 3.125rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.3125rem !important;
}

.pt-2 {
  padding-top: 0.625rem !important;
}

.pt-3 {
  padding-top: 0.9375rem !important;
}

.pt-4 {
  padding-top: 1.25rem !important;
}

.pt-5 {
  padding-top: 1.625rem !important;
}

.pt-6 {
  padding-top: 1.875rem !important;
}

.pt-7 {
  padding-top: 2.1875rem !important;
}

.pt-8 {
  padding-top: 2.5rem !important;
}

.pt-9 {
  padding-top: 2.8125rem !important;
}

.pt-10 {
  padding-top: 3.125rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.3125rem !important;
}

.pe-2 {
  padding-right: 0.625rem !important;
}

.pe-3 {
  padding-right: 0.9375rem !important;
}

.pe-4 {
  padding-right: 1.25rem !important;
}

.pe-5 {
  padding-right: 1.625rem !important;
}

.pe-6 {
  padding-right: 1.875rem !important;
}

.pe-7 {
  padding-right: 2.1875rem !important;
}

.pe-8 {
  padding-right: 2.5rem !important;
}

.pe-9 {
  padding-right: 2.8125rem !important;
}

.pe-10 {
  padding-right: 3.125rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.3125rem !important;
}

.pb-2 {
  padding-bottom: 0.625rem !important;
}

.pb-3 {
  padding-bottom: 0.9375rem !important;
}

.pb-4 {
  padding-bottom: 1.25rem !important;
}

.pb-5 {
  padding-bottom: 1.625rem !important;
}

.pb-6 {
  padding-bottom: 1.875rem !important;
}

.pb-7 {
  padding-bottom: 2.1875rem !important;
}

.pb-8 {
  padding-bottom: 2.5rem !important;
}

.pb-9 {
  padding-bottom: 2.8125rem !important;
}

.pb-10 {
  padding-bottom: 3.125rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.3125rem !important;
}

.ps-2 {
  padding-left: 0.625rem !important;
}

.ps-3 {
  padding-left: 0.9375rem !important;
}

.ps-4 {
  padding-left: 1.25rem !important;
}

.ps-5 {
  padding-left: 1.625rem !important;
}

.ps-6 {
  padding-left: 1.875rem !important;
}

.ps-7 {
  padding-left: 2.1875rem !important;
}

.ps-8 {
  padding-left: 2.5rem !important;
}

.ps-9 {
  padding-left: 2.8125rem !important;
}

.ps-10 {
  padding-left: 3.125rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.35938rem + 1.3125vw) !important;
}

.fs-2 {
  font-size: calc(1.3125rem + 0.75vw) !important;
}

.fs-3 {
  font-size: calc(1.28906rem + 0.46875vw) !important;
}

.fs-4 {
  font-size: calc(1.26563rem + 0.1875vw) !important;
}

.fs-5 {
  font-size: 1.17188rem !important;
}

.fs-6 {
  font-size: 0.9375rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.8 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  color: #02126a !important;
}

.text-secondary {
  color: #fc7418 !important;
}

.text-success {
  color: #32b618 !important;
}

.text-info {
  color: #0dcaf0 !important;
}

.text-warning {
  color: #fc7418 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.text-light {
  color: #f8f8f8 !important;
}

.text-dark {
  color: #32383e !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #767988 !important;
}

.text-muted {
  color: #fbfbfb !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.bg-primary {
  background-color: #02126a !important;
}

.bg-secondary {
  background-color: #fc7418 !important;
}

.bg-success {
  background-color: #32b618 !important;
}

.bg-info {
  background-color: #0dcaf0 !important;
}

.bg-warning {
  background-color: #fc7418 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.bg-light {
  background-color: #f8f8f8 !important;
}

.bg-dark {
  background-color: #32383e !important;
}

.bg-body {
  background-color: #fff !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 4px !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 4px !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.rounded-end {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.rounded-bottom {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.rounded-start {
  border-bottom-left-radius: 4px !important;
  border-top-left-radius: 4px !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.3125rem !important;
  }
  .gap-sm-2 {
    gap: 0.625rem !important;
  }
  .gap-sm-3 {
    gap: 0.9375rem !important;
  }
  .gap-sm-4 {
    gap: 1.25rem !important;
  }
  .gap-sm-5 {
    gap: 1.625rem !important;
  }
  .gap-sm-6 {
    gap: 1.875rem !important;
  }
  .gap-sm-7 {
    gap: 2.1875rem !important;
  }
  .gap-sm-8 {
    gap: 2.5rem !important;
  }
  .gap-sm-9 {
    gap: 2.8125rem !important;
  }
  .gap-sm-10 {
    gap: 3.125rem !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.3125rem !important;
  }
  .m-sm-2 {
    margin: 0.625rem !important;
  }
  .m-sm-3 {
    margin: 0.9375rem !important;
  }
  .m-sm-4 {
    margin: 1.25rem !important;
  }
  .m-sm-5 {
    margin: 1.625rem !important;
  }
  .m-sm-6 {
    margin: 1.875rem !important;
  }
  .m-sm-7 {
    margin: 2.1875rem !important;
  }
  .m-sm-8 {
    margin: 2.5rem !important;
  }
  .m-sm-9 {
    margin: 2.8125rem !important;
  }
  .m-sm-10 {
    margin: 3.125rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-sm-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-sm-5 {
    margin-right: 1.625rem !important;
    margin-left: 1.625rem !important;
  }
  .mx-sm-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-sm-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important;
  }
  .mx-sm-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-sm-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important;
  }
  .mx-sm-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-sm-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-sm-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-sm-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-sm-5 {
    margin-top: 1.625rem !important;
    margin-bottom: 1.625rem !important;
  }
  .my-sm-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-sm-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important;
  }
  .my-sm-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-sm-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important;
  }
  .my-sm-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.625rem !important;
  }
  .mt-sm-3 {
    margin-top: 0.9375rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.25rem !important;
  }
  .mt-sm-5 {
    margin-top: 1.625rem !important;
  }
  .mt-sm-6 {
    margin-top: 1.875rem !important;
  }
  .mt-sm-7 {
    margin-top: 2.1875rem !important;
  }
  .mt-sm-8 {
    margin-top: 2.5rem !important;
  }
  .mt-sm-9 {
    margin-top: 2.8125rem !important;
  }
  .mt-sm-10 {
    margin-top: 3.125rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.3125rem !important;
  }
  .me-sm-2 {
    margin-right: 0.625rem !important;
  }
  .me-sm-3 {
    margin-right: 0.9375rem !important;
  }
  .me-sm-4 {
    margin-right: 1.25rem !important;
  }
  .me-sm-5 {
    margin-right: 1.625rem !important;
  }
  .me-sm-6 {
    margin-right: 1.875rem !important;
  }
  .me-sm-7 {
    margin-right: 2.1875rem !important;
  }
  .me-sm-8 {
    margin-right: 2.5rem !important;
  }
  .me-sm-9 {
    margin-right: 2.8125rem !important;
  }
  .me-sm-10 {
    margin-right: 3.125rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.25rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 1.625rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 1.875rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 2.1875rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 2.5rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 2.8125rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 3.125rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.3125rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.625rem !important;
  }
  .ms-sm-3 {
    margin-left: 0.9375rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.25rem !important;
  }
  .ms-sm-5 {
    margin-left: 1.625rem !important;
  }
  .ms-sm-6 {
    margin-left: 1.875rem !important;
  }
  .ms-sm-7 {
    margin-left: 2.1875rem !important;
  }
  .ms-sm-8 {
    margin-left: 2.5rem !important;
  }
  .ms-sm-9 {
    margin-left: 2.8125rem !important;
  }
  .ms-sm-10 {
    margin-left: 3.125rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.3125rem !important;
  }
  .m-sm-n2 {
    margin: -0.625rem !important;
  }
  .m-sm-n3 {
    margin: -0.9375rem !important;
  }
  .m-sm-n4 {
    margin: -1.25rem !important;
  }
  .m-sm-n5 {
    margin: -1.625rem !important;
  }
  .m-sm-n6 {
    margin: -1.875rem !important;
  }
  .m-sm-n7 {
    margin: -2.1875rem !important;
  }
  .m-sm-n8 {
    margin: -2.5rem !important;
  }
  .m-sm-n9 {
    margin: -2.8125rem !important;
  }
  .m-sm-n10 {
    margin: -3.125rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-sm-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-sm-n5 {
    margin-right: -1.625rem !important;
    margin-left: -1.625rem !important;
  }
  .mx-sm-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-sm-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important;
  }
  .mx-sm-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-sm-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important;
  }
  .mx-sm-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-sm-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-sm-n5 {
    margin-top: -1.625rem !important;
    margin-bottom: -1.625rem !important;
  }
  .my-sm-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-sm-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important;
  }
  .my-sm-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-sm-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important;
  }
  .my-sm-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.3125rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.625rem !important;
  }
  .mt-sm-n3 {
    margin-top: -0.9375rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.25rem !important;
  }
  .mt-sm-n5 {
    margin-top: -1.625rem !important;
  }
  .mt-sm-n6 {
    margin-top: -1.875rem !important;
  }
  .mt-sm-n7 {
    margin-top: -2.1875rem !important;
  }
  .mt-sm-n8 {
    margin-top: -2.5rem !important;
  }
  .mt-sm-n9 {
    margin-top: -2.8125rem !important;
  }
  .mt-sm-n10 {
    margin-top: -3.125rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.3125rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.625rem !important;
  }
  .me-sm-n3 {
    margin-right: -0.9375rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.25rem !important;
  }
  .me-sm-n5 {
    margin-right: -1.625rem !important;
  }
  .me-sm-n6 {
    margin-right: -1.875rem !important;
  }
  .me-sm-n7 {
    margin-right: -2.1875rem !important;
  }
  .me-sm-n8 {
    margin-right: -2.5rem !important;
  }
  .me-sm-n9 {
    margin-right: -2.8125rem !important;
  }
  .me-sm-n10 {
    margin-right: -3.125rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.625rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.25rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -1.625rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -1.875rem !important;
  }
  .mb-sm-n7 {
    margin-bottom: -2.1875rem !important;
  }
  .mb-sm-n8 {
    margin-bottom: -2.5rem !important;
  }
  .mb-sm-n9 {
    margin-bottom: -2.8125rem !important;
  }
  .mb-sm-n10 {
    margin-bottom: -3.125rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.3125rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.625rem !important;
  }
  .ms-sm-n3 {
    margin-left: -0.9375rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.25rem !important;
  }
  .ms-sm-n5 {
    margin-left: -1.625rem !important;
  }
  .ms-sm-n6 {
    margin-left: -1.875rem !important;
  }
  .ms-sm-n7 {
    margin-left: -2.1875rem !important;
  }
  .ms-sm-n8 {
    margin-left: -2.5rem !important;
  }
  .ms-sm-n9 {
    margin-left: -2.8125rem !important;
  }
  .ms-sm-n10 {
    margin-left: -3.125rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.3125rem !important;
  }
  .p-sm-2 {
    padding: 0.625rem !important;
  }
  .p-sm-3 {
    padding: 0.9375rem !important;
  }
  .p-sm-4 {
    padding: 1.25rem !important;
  }
  .p-sm-5 {
    padding: 1.625rem !important;
  }
  .p-sm-6 {
    padding: 1.875rem !important;
  }
  .p-sm-7 {
    padding: 2.1875rem !important;
  }
  .p-sm-8 {
    padding: 2.5rem !important;
  }
  .p-sm-9 {
    padding: 2.8125rem !important;
  }
  .p-sm-10 {
    padding: 3.125rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-sm-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-sm-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-sm-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-sm-5 {
    padding-right: 1.625rem !important;
    padding-left: 1.625rem !important;
  }
  .px-sm-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-sm-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important;
  }
  .px-sm-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-sm-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important;
  }
  .px-sm-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-sm-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-sm-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-sm-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-sm-5 {
    padding-top: 1.625rem !important;
    padding-bottom: 1.625rem !important;
  }
  .py-sm-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-sm-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important;
  }
  .py-sm-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-sm-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important;
  }
  .py-sm-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.625rem !important;
  }
  .pt-sm-3 {
    padding-top: 0.9375rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.25rem !important;
  }
  .pt-sm-5 {
    padding-top: 1.625rem !important;
  }
  .pt-sm-6 {
    padding-top: 1.875rem !important;
  }
  .pt-sm-7 {
    padding-top: 2.1875rem !important;
  }
  .pt-sm-8 {
    padding-top: 2.5rem !important;
  }
  .pt-sm-9 {
    padding-top: 2.8125rem !important;
  }
  .pt-sm-10 {
    padding-top: 3.125rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.3125rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.625rem !important;
  }
  .pe-sm-3 {
    padding-right: 0.9375rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.25rem !important;
  }
  .pe-sm-5 {
    padding-right: 1.625rem !important;
  }
  .pe-sm-6 {
    padding-right: 1.875rem !important;
  }
  .pe-sm-7 {
    padding-right: 2.1875rem !important;
  }
  .pe-sm-8 {
    padding-right: 2.5rem !important;
  }
  .pe-sm-9 {
    padding-right: 2.8125rem !important;
  }
  .pe-sm-10 {
    padding-right: 3.125rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.25rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 1.625rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 1.875rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 2.1875rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 2.5rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 2.8125rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 3.125rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.3125rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.625rem !important;
  }
  .ps-sm-3 {
    padding-left: 0.9375rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.25rem !important;
  }
  .ps-sm-5 {
    padding-left: 1.625rem !important;
  }
  .ps-sm-6 {
    padding-left: 1.875rem !important;
  }
  .ps-sm-7 {
    padding-left: 2.1875rem !important;
  }
  .ps-sm-8 {
    padding-left: 2.5rem !important;
  }
  .ps-sm-9 {
    padding-left: 2.8125rem !important;
  }
  .ps-sm-10 {
    padding-left: 3.125rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.3125rem !important;
  }
  .gap-md-2 {
    gap: 0.625rem !important;
  }
  .gap-md-3 {
    gap: 0.9375rem !important;
  }
  .gap-md-4 {
    gap: 1.25rem !important;
  }
  .gap-md-5 {
    gap: 1.625rem !important;
  }
  .gap-md-6 {
    gap: 1.875rem !important;
  }
  .gap-md-7 {
    gap: 2.1875rem !important;
  }
  .gap-md-8 {
    gap: 2.5rem !important;
  }
  .gap-md-9 {
    gap: 2.8125rem !important;
  }
  .gap-md-10 {
    gap: 3.125rem !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.3125rem !important;
  }
  .m-md-2 {
    margin: 0.625rem !important;
  }
  .m-md-3 {
    margin: 0.9375rem !important;
  }
  .m-md-4 {
    margin: 1.25rem !important;
  }
  .m-md-5 {
    margin: 1.625rem !important;
  }
  .m-md-6 {
    margin: 1.875rem !important;
  }
  .m-md-7 {
    margin: 2.1875rem !important;
  }
  .m-md-8 {
    margin: 2.5rem !important;
  }
  .m-md-9 {
    margin: 2.8125rem !important;
  }
  .m-md-10 {
    margin: 3.125rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-md-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-md-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-md-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-md-5 {
    margin-right: 1.625rem !important;
    margin-left: 1.625rem !important;
  }
  .mx-md-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-md-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important;
  }
  .mx-md-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-md-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important;
  }
  .mx-md-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-md-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-md-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-md-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-md-5 {
    margin-top: 1.625rem !important;
    margin-bottom: 1.625rem !important;
  }
  .my-md-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-md-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important;
  }
  .my-md-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-md-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important;
  }
  .my-md-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-md-2 {
    margin-top: 0.625rem !important;
  }
  .mt-md-3 {
    margin-top: 0.9375rem !important;
  }
  .mt-md-4 {
    margin-top: 1.25rem !important;
  }
  .mt-md-5 {
    margin-top: 1.625rem !important;
  }
  .mt-md-6 {
    margin-top: 1.875rem !important;
  }
  .mt-md-7 {
    margin-top: 2.1875rem !important;
  }
  .mt-md-8 {
    margin-top: 2.5rem !important;
  }
  .mt-md-9 {
    margin-top: 2.8125rem !important;
  }
  .mt-md-10 {
    margin-top: 3.125rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.3125rem !important;
  }
  .me-md-2 {
    margin-right: 0.625rem !important;
  }
  .me-md-3 {
    margin-right: 0.9375rem !important;
  }
  .me-md-4 {
    margin-right: 1.25rem !important;
  }
  .me-md-5 {
    margin-right: 1.625rem !important;
  }
  .me-md-6 {
    margin-right: 1.875rem !important;
  }
  .me-md-7 {
    margin-right: 2.1875rem !important;
  }
  .me-md-8 {
    margin-right: 2.5rem !important;
  }
  .me-md-9 {
    margin-right: 2.8125rem !important;
  }
  .me-md-10 {
    margin-right: 3.125rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-md-3 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.25rem !important;
  }
  .mb-md-5 {
    margin-bottom: 1.625rem !important;
  }
  .mb-md-6 {
    margin-bottom: 1.875rem !important;
  }
  .mb-md-7 {
    margin-bottom: 2.1875rem !important;
  }
  .mb-md-8 {
    margin-bottom: 2.5rem !important;
  }
  .mb-md-9 {
    margin-bottom: 2.8125rem !important;
  }
  .mb-md-10 {
    margin-bottom: 3.125rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.3125rem !important;
  }
  .ms-md-2 {
    margin-left: 0.625rem !important;
  }
  .ms-md-3 {
    margin-left: 0.9375rem !important;
  }
  .ms-md-4 {
    margin-left: 1.25rem !important;
  }
  .ms-md-5 {
    margin-left: 1.625rem !important;
  }
  .ms-md-6 {
    margin-left: 1.875rem !important;
  }
  .ms-md-7 {
    margin-left: 2.1875rem !important;
  }
  .ms-md-8 {
    margin-left: 2.5rem !important;
  }
  .ms-md-9 {
    margin-left: 2.8125rem !important;
  }
  .ms-md-10 {
    margin-left: 3.125rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.3125rem !important;
  }
  .m-md-n2 {
    margin: -0.625rem !important;
  }
  .m-md-n3 {
    margin: -0.9375rem !important;
  }
  .m-md-n4 {
    margin: -1.25rem !important;
  }
  .m-md-n5 {
    margin: -1.625rem !important;
  }
  .m-md-n6 {
    margin: -1.875rem !important;
  }
  .m-md-n7 {
    margin: -2.1875rem !important;
  }
  .m-md-n8 {
    margin: -2.5rem !important;
  }
  .m-md-n9 {
    margin: -2.8125rem !important;
  }
  .m-md-n10 {
    margin: -3.125rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-md-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-md-n5 {
    margin-right: -1.625rem !important;
    margin-left: -1.625rem !important;
  }
  .mx-md-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-md-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important;
  }
  .mx-md-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-md-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important;
  }
  .mx-md-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .my-md-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }
  .my-md-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-md-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-md-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-md-n5 {
    margin-top: -1.625rem !important;
    margin-bottom: -1.625rem !important;
  }
  .my-md-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-md-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important;
  }
  .my-md-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-md-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important;
  }
  .my-md-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.3125rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.625rem !important;
  }
  .mt-md-n3 {
    margin-top: -0.9375rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.25rem !important;
  }
  .mt-md-n5 {
    margin-top: -1.625rem !important;
  }
  .mt-md-n6 {
    margin-top: -1.875rem !important;
  }
  .mt-md-n7 {
    margin-top: -2.1875rem !important;
  }
  .mt-md-n8 {
    margin-top: -2.5rem !important;
  }
  .mt-md-n9 {
    margin-top: -2.8125rem !important;
  }
  .mt-md-n10 {
    margin-top: -3.125rem !important;
  }
  .me-md-n1 {
    margin-right: -0.3125rem !important;
  }
  .me-md-n2 {
    margin-right: -0.625rem !important;
  }
  .me-md-n3 {
    margin-right: -0.9375rem !important;
  }
  .me-md-n4 {
    margin-right: -1.25rem !important;
  }
  .me-md-n5 {
    margin-right: -1.625rem !important;
  }
  .me-md-n6 {
    margin-right: -1.875rem !important;
  }
  .me-md-n7 {
    margin-right: -2.1875rem !important;
  }
  .me-md-n8 {
    margin-right: -2.5rem !important;
  }
  .me-md-n9 {
    margin-right: -2.8125rem !important;
  }
  .me-md-n10 {
    margin-right: -3.125rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.625rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.25rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -1.625rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -1.875rem !important;
  }
  .mb-md-n7 {
    margin-bottom: -2.1875rem !important;
  }
  .mb-md-n8 {
    margin-bottom: -2.5rem !important;
  }
  .mb-md-n9 {
    margin-bottom: -2.8125rem !important;
  }
  .mb-md-n10 {
    margin-bottom: -3.125rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.3125rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.625rem !important;
  }
  .ms-md-n3 {
    margin-left: -0.9375rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.25rem !important;
  }
  .ms-md-n5 {
    margin-left: -1.625rem !important;
  }
  .ms-md-n6 {
    margin-left: -1.875rem !important;
  }
  .ms-md-n7 {
    margin-left: -2.1875rem !important;
  }
  .ms-md-n8 {
    margin-left: -2.5rem !important;
  }
  .ms-md-n9 {
    margin-left: -2.8125rem !important;
  }
  .ms-md-n10 {
    margin-left: -3.125rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.3125rem !important;
  }
  .p-md-2 {
    padding: 0.625rem !important;
  }
  .p-md-3 {
    padding: 0.9375rem !important;
  }
  .p-md-4 {
    padding: 1.25rem !important;
  }
  .p-md-5 {
    padding: 1.625rem !important;
  }
  .p-md-6 {
    padding: 1.875rem !important;
  }
  .p-md-7 {
    padding: 2.1875rem !important;
  }
  .p-md-8 {
    padding: 2.5rem !important;
  }
  .p-md-9 {
    padding: 2.8125rem !important;
  }
  .p-md-10 {
    padding: 3.125rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-md-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-md-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-md-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-md-5 {
    padding-right: 1.625rem !important;
    padding-left: 1.625rem !important;
  }
  .px-md-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-md-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important;
  }
  .px-md-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-md-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important;
  }
  .px-md-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-md-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-md-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-md-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-md-5 {
    padding-top: 1.625rem !important;
    padding-bottom: 1.625rem !important;
  }
  .py-md-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-md-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important;
  }
  .py-md-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-md-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important;
  }
  .py-md-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-md-2 {
    padding-top: 0.625rem !important;
  }
  .pt-md-3 {
    padding-top: 0.9375rem !important;
  }
  .pt-md-4 {
    padding-top: 1.25rem !important;
  }
  .pt-md-5 {
    padding-top: 1.625rem !important;
  }
  .pt-md-6 {
    padding-top: 1.875rem !important;
  }
  .pt-md-7 {
    padding-top: 2.1875rem !important;
  }
  .pt-md-8 {
    padding-top: 2.5rem !important;
  }
  .pt-md-9 {
    padding-top: 2.8125rem !important;
  }
  .pt-md-10 {
    padding-top: 3.125rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.3125rem !important;
  }
  .pe-md-2 {
    padding-right: 0.625rem !important;
  }
  .pe-md-3 {
    padding-right: 0.9375rem !important;
  }
  .pe-md-4 {
    padding-right: 1.25rem !important;
  }
  .pe-md-5 {
    padding-right: 1.625rem !important;
  }
  .pe-md-6 {
    padding-right: 1.875rem !important;
  }
  .pe-md-7 {
    padding-right: 2.1875rem !important;
  }
  .pe-md-8 {
    padding-right: 2.5rem !important;
  }
  .pe-md-9 {
    padding-right: 2.8125rem !important;
  }
  .pe-md-10 {
    padding-right: 3.125rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-md-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.25rem !important;
  }
  .pb-md-5 {
    padding-bottom: 1.625rem !important;
  }
  .pb-md-6 {
    padding-bottom: 1.875rem !important;
  }
  .pb-md-7 {
    padding-bottom: 2.1875rem !important;
  }
  .pb-md-8 {
    padding-bottom: 2.5rem !important;
  }
  .pb-md-9 {
    padding-bottom: 2.8125rem !important;
  }
  .pb-md-10 {
    padding-bottom: 3.125rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.3125rem !important;
  }
  .ps-md-2 {
    padding-left: 0.625rem !important;
  }
  .ps-md-3 {
    padding-left: 0.9375rem !important;
  }
  .ps-md-4 {
    padding-left: 1.25rem !important;
  }
  .ps-md-5 {
    padding-left: 1.625rem !important;
  }
  .ps-md-6 {
    padding-left: 1.875rem !important;
  }
  .ps-md-7 {
    padding-left: 2.1875rem !important;
  }
  .ps-md-8 {
    padding-left: 2.5rem !important;
  }
  .ps-md-9 {
    padding-left: 2.8125rem !important;
  }
  .ps-md-10 {
    padding-left: 3.125rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.3125rem !important;
  }
  .gap-lg-2 {
    gap: 0.625rem !important;
  }
  .gap-lg-3 {
    gap: 0.9375rem !important;
  }
  .gap-lg-4 {
    gap: 1.25rem !important;
  }
  .gap-lg-5 {
    gap: 1.625rem !important;
  }
  .gap-lg-6 {
    gap: 1.875rem !important;
  }
  .gap-lg-7 {
    gap: 2.1875rem !important;
  }
  .gap-lg-8 {
    gap: 2.5rem !important;
  }
  .gap-lg-9 {
    gap: 2.8125rem !important;
  }
  .gap-lg-10 {
    gap: 3.125rem !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.3125rem !important;
  }
  .m-lg-2 {
    margin: 0.625rem !important;
  }
  .m-lg-3 {
    margin: 0.9375rem !important;
  }
  .m-lg-4 {
    margin: 1.25rem !important;
  }
  .m-lg-5 {
    margin: 1.625rem !important;
  }
  .m-lg-6 {
    margin: 1.875rem !important;
  }
  .m-lg-7 {
    margin: 2.1875rem !important;
  }
  .m-lg-8 {
    margin: 2.5rem !important;
  }
  .m-lg-9 {
    margin: 2.8125rem !important;
  }
  .m-lg-10 {
    margin: 3.125rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-lg-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-lg-5 {
    margin-right: 1.625rem !important;
    margin-left: 1.625rem !important;
  }
  .mx-lg-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-lg-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important;
  }
  .mx-lg-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-lg-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important;
  }
  .mx-lg-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-lg-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-lg-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-lg-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-lg-5 {
    margin-top: 1.625rem !important;
    margin-bottom: 1.625rem !important;
  }
  .my-lg-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-lg-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important;
  }
  .my-lg-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-lg-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important;
  }
  .my-lg-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.625rem !important;
  }
  .mt-lg-3 {
    margin-top: 0.9375rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.25rem !important;
  }
  .mt-lg-5 {
    margin-top: 1.625rem !important;
  }
  .mt-lg-6 {
    margin-top: 1.875rem !important;
  }
  .mt-lg-7 {
    margin-top: 2.1875rem !important;
  }
  .mt-lg-8 {
    margin-top: 2.5rem !important;
  }
  .mt-lg-9 {
    margin-top: 2.8125rem !important;
  }
  .mt-lg-10 {
    margin-top: 3.125rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.3125rem !important;
  }
  .me-lg-2 {
    margin-right: 0.625rem !important;
  }
  .me-lg-3 {
    margin-right: 0.9375rem !important;
  }
  .me-lg-4 {
    margin-right: 1.25rem !important;
  }
  .me-lg-5 {
    margin-right: 1.625rem !important;
  }
  .me-lg-6 {
    margin-right: 1.875rem !important;
  }
  .me-lg-7 {
    margin-right: 2.1875rem !important;
  }
  .me-lg-8 {
    margin-right: 2.5rem !important;
  }
  .me-lg-9 {
    margin-right: 2.8125rem !important;
  }
  .me-lg-10 {
    margin-right: 3.125rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.25rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 1.625rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 1.875rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 2.1875rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 2.5rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 2.8125rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 3.125rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.3125rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.625rem !important;
  }
  .ms-lg-3 {
    margin-left: 0.9375rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.25rem !important;
  }
  .ms-lg-5 {
    margin-left: 1.625rem !important;
  }
  .ms-lg-6 {
    margin-left: 1.875rem !important;
  }
  .ms-lg-7 {
    margin-left: 2.1875rem !important;
  }
  .ms-lg-8 {
    margin-left: 2.5rem !important;
  }
  .ms-lg-9 {
    margin-left: 2.8125rem !important;
  }
  .ms-lg-10 {
    margin-left: 3.125rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.3125rem !important;
  }
  .m-lg-n2 {
    margin: -0.625rem !important;
  }
  .m-lg-n3 {
    margin: -0.9375rem !important;
  }
  .m-lg-n4 {
    margin: -1.25rem !important;
  }
  .m-lg-n5 {
    margin: -1.625rem !important;
  }
  .m-lg-n6 {
    margin: -1.875rem !important;
  }
  .m-lg-n7 {
    margin: -2.1875rem !important;
  }
  .m-lg-n8 {
    margin: -2.5rem !important;
  }
  .m-lg-n9 {
    margin: -2.8125rem !important;
  }
  .m-lg-n10 {
    margin: -3.125rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-lg-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-lg-n5 {
    margin-right: -1.625rem !important;
    margin-left: -1.625rem !important;
  }
  .mx-lg-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-lg-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important;
  }
  .mx-lg-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-lg-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important;
  }
  .mx-lg-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-lg-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-lg-n5 {
    margin-top: -1.625rem !important;
    margin-bottom: -1.625rem !important;
  }
  .my-lg-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-lg-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important;
  }
  .my-lg-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-lg-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important;
  }
  .my-lg-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.3125rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.625rem !important;
  }
  .mt-lg-n3 {
    margin-top: -0.9375rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.25rem !important;
  }
  .mt-lg-n5 {
    margin-top: -1.625rem !important;
  }
  .mt-lg-n6 {
    margin-top: -1.875rem !important;
  }
  .mt-lg-n7 {
    margin-top: -2.1875rem !important;
  }
  .mt-lg-n8 {
    margin-top: -2.5rem !important;
  }
  .mt-lg-n9 {
    margin-top: -2.8125rem !important;
  }
  .mt-lg-n10 {
    margin-top: -3.125rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.3125rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.625rem !important;
  }
  .me-lg-n3 {
    margin-right: -0.9375rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.25rem !important;
  }
  .me-lg-n5 {
    margin-right: -1.625rem !important;
  }
  .me-lg-n6 {
    margin-right: -1.875rem !important;
  }
  .me-lg-n7 {
    margin-right: -2.1875rem !important;
  }
  .me-lg-n8 {
    margin-right: -2.5rem !important;
  }
  .me-lg-n9 {
    margin-right: -2.8125rem !important;
  }
  .me-lg-n10 {
    margin-right: -3.125rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.625rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.25rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -1.625rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -1.875rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -2.1875rem !important;
  }
  .mb-lg-n8 {
    margin-bottom: -2.5rem !important;
  }
  .mb-lg-n9 {
    margin-bottom: -2.8125rem !important;
  }
  .mb-lg-n10 {
    margin-bottom: -3.125rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.3125rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.625rem !important;
  }
  .ms-lg-n3 {
    margin-left: -0.9375rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.25rem !important;
  }
  .ms-lg-n5 {
    margin-left: -1.625rem !important;
  }
  .ms-lg-n6 {
    margin-left: -1.875rem !important;
  }
  .ms-lg-n7 {
    margin-left: -2.1875rem !important;
  }
  .ms-lg-n8 {
    margin-left: -2.5rem !important;
  }
  .ms-lg-n9 {
    margin-left: -2.8125rem !important;
  }
  .ms-lg-n10 {
    margin-left: -3.125rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.3125rem !important;
  }
  .p-lg-2 {
    padding: 0.625rem !important;
  }
  .p-lg-3 {
    padding: 0.9375rem !important;
  }
  .p-lg-4 {
    padding: 1.25rem !important;
  }
  .p-lg-5 {
    padding: 1.625rem !important;
  }
  .p-lg-6 {
    padding: 1.875rem !important;
  }
  .p-lg-7 {
    padding: 2.1875rem !important;
  }
  .p-lg-8 {
    padding: 2.5rem !important;
  }
  .p-lg-9 {
    padding: 2.8125rem !important;
  }
  .p-lg-10 {
    padding: 3.125rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-lg-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-lg-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-lg-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-lg-5 {
    padding-right: 1.625rem !important;
    padding-left: 1.625rem !important;
  }
  .px-lg-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-lg-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important;
  }
  .px-lg-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-lg-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important;
  }
  .px-lg-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-lg-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-lg-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-lg-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-lg-5 {
    padding-top: 1.625rem !important;
    padding-bottom: 1.625rem !important;
  }
  .py-lg-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-lg-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important;
  }
  .py-lg-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-lg-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important;
  }
  .py-lg-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.625rem !important;
  }
  .pt-lg-3 {
    padding-top: 0.9375rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.25rem !important;
  }
  .pt-lg-5 {
    padding-top: 1.625rem !important;
  }
  .pt-lg-6 {
    padding-top: 1.875rem !important;
  }
  .pt-lg-7 {
    padding-top: 2.1875rem !important;
  }
  .pt-lg-8 {
    padding-top: 2.5rem !important;
  }
  .pt-lg-9 {
    padding-top: 2.8125rem !important;
  }
  .pt-lg-10 {
    padding-top: 3.125rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.3125rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.625rem !important;
  }
  .pe-lg-3 {
    padding-right: 0.9375rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.25rem !important;
  }
  .pe-lg-5 {
    padding-right: 1.625rem !important;
  }
  .pe-lg-6 {
    padding-right: 1.875rem !important;
  }
  .pe-lg-7 {
    padding-right: 2.1875rem !important;
  }
  .pe-lg-8 {
    padding-right: 2.5rem !important;
  }
  .pe-lg-9 {
    padding-right: 2.8125rem !important;
  }
  .pe-lg-10 {
    padding-right: 3.125rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.25rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 1.625rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 1.875rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 2.1875rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 2.5rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 2.8125rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 3.125rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.3125rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.625rem !important;
  }
  .ps-lg-3 {
    padding-left: 0.9375rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.25rem !important;
  }
  .ps-lg-5 {
    padding-left: 1.625rem !important;
  }
  .ps-lg-6 {
    padding-left: 1.875rem !important;
  }
  .ps-lg-7 {
    padding-left: 2.1875rem !important;
  }
  .ps-lg-8 {
    padding-left: 2.5rem !important;
  }
  .ps-lg-9 {
    padding-left: 2.8125rem !important;
  }
  .ps-lg-10 {
    padding-left: 3.125rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.3125rem !important;
  }
  .gap-xl-2 {
    gap: 0.625rem !important;
  }
  .gap-xl-3 {
    gap: 0.9375rem !important;
  }
  .gap-xl-4 {
    gap: 1.25rem !important;
  }
  .gap-xl-5 {
    gap: 1.625rem !important;
  }
  .gap-xl-6 {
    gap: 1.875rem !important;
  }
  .gap-xl-7 {
    gap: 2.1875rem !important;
  }
  .gap-xl-8 {
    gap: 2.5rem !important;
  }
  .gap-xl-9 {
    gap: 2.8125rem !important;
  }
  .gap-xl-10 {
    gap: 3.125rem !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.3125rem !important;
  }
  .m-xl-2 {
    margin: 0.625rem !important;
  }
  .m-xl-3 {
    margin: 0.9375rem !important;
  }
  .m-xl-4 {
    margin: 1.25rem !important;
  }
  .m-xl-5 {
    margin: 1.625rem !important;
  }
  .m-xl-6 {
    margin: 1.875rem !important;
  }
  .m-xl-7 {
    margin: 2.1875rem !important;
  }
  .m-xl-8 {
    margin: 2.5rem !important;
  }
  .m-xl-9 {
    margin: 2.8125rem !important;
  }
  .m-xl-10 {
    margin: 3.125rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-xl-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xl-5 {
    margin-right: 1.625rem !important;
    margin-left: 1.625rem !important;
  }
  .mx-xl-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-xl-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important;
  }
  .mx-xl-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xl-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important;
  }
  .mx-xl-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-xl-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-xl-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-xl-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xl-5 {
    margin-top: 1.625rem !important;
    margin-bottom: 1.625rem !important;
  }
  .my-xl-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-xl-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important;
  }
  .my-xl-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xl-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important;
  }
  .my-xl-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.625rem !important;
  }
  .mt-xl-3 {
    margin-top: 0.9375rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.25rem !important;
  }
  .mt-xl-5 {
    margin-top: 1.625rem !important;
  }
  .mt-xl-6 {
    margin-top: 1.875rem !important;
  }
  .mt-xl-7 {
    margin-top: 2.1875rem !important;
  }
  .mt-xl-8 {
    margin-top: 2.5rem !important;
  }
  .mt-xl-9 {
    margin-top: 2.8125rem !important;
  }
  .mt-xl-10 {
    margin-top: 3.125rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.3125rem !important;
  }
  .me-xl-2 {
    margin-right: 0.625rem !important;
  }
  .me-xl-3 {
    margin-right: 0.9375rem !important;
  }
  .me-xl-4 {
    margin-right: 1.25rem !important;
  }
  .me-xl-5 {
    margin-right: 1.625rem !important;
  }
  .me-xl-6 {
    margin-right: 1.875rem !important;
  }
  .me-xl-7 {
    margin-right: 2.1875rem !important;
  }
  .me-xl-8 {
    margin-right: 2.5rem !important;
  }
  .me-xl-9 {
    margin-right: 2.8125rem !important;
  }
  .me-xl-10 {
    margin-right: 3.125rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 1.625rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 1.875rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 2.1875rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 2.8125rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 3.125rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.3125rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.625rem !important;
  }
  .ms-xl-3 {
    margin-left: 0.9375rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.25rem !important;
  }
  .ms-xl-5 {
    margin-left: 1.625rem !important;
  }
  .ms-xl-6 {
    margin-left: 1.875rem !important;
  }
  .ms-xl-7 {
    margin-left: 2.1875rem !important;
  }
  .ms-xl-8 {
    margin-left: 2.5rem !important;
  }
  .ms-xl-9 {
    margin-left: 2.8125rem !important;
  }
  .ms-xl-10 {
    margin-left: 3.125rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.3125rem !important;
  }
  .m-xl-n2 {
    margin: -0.625rem !important;
  }
  .m-xl-n3 {
    margin: -0.9375rem !important;
  }
  .m-xl-n4 {
    margin: -1.25rem !important;
  }
  .m-xl-n5 {
    margin: -1.625rem !important;
  }
  .m-xl-n6 {
    margin: -1.875rem !important;
  }
  .m-xl-n7 {
    margin: -2.1875rem !important;
  }
  .m-xl-n8 {
    margin: -2.5rem !important;
  }
  .m-xl-n9 {
    margin: -2.8125rem !important;
  }
  .m-xl-n10 {
    margin: -3.125rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-xl-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-xl-n5 {
    margin-right: -1.625rem !important;
    margin-left: -1.625rem !important;
  }
  .mx-xl-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-xl-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important;
  }
  .mx-xl-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-xl-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important;
  }
  .mx-xl-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-xl-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-xl-n5 {
    margin-top: -1.625rem !important;
    margin-bottom: -1.625rem !important;
  }
  .my-xl-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-xl-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important;
  }
  .my-xl-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-xl-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important;
  }
  .my-xl-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.3125rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.625rem !important;
  }
  .mt-xl-n3 {
    margin-top: -0.9375rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.25rem !important;
  }
  .mt-xl-n5 {
    margin-top: -1.625rem !important;
  }
  .mt-xl-n6 {
    margin-top: -1.875rem !important;
  }
  .mt-xl-n7 {
    margin-top: -2.1875rem !important;
  }
  .mt-xl-n8 {
    margin-top: -2.5rem !important;
  }
  .mt-xl-n9 {
    margin-top: -2.8125rem !important;
  }
  .mt-xl-n10 {
    margin-top: -3.125rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.3125rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.625rem !important;
  }
  .me-xl-n3 {
    margin-right: -0.9375rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.25rem !important;
  }
  .me-xl-n5 {
    margin-right: -1.625rem !important;
  }
  .me-xl-n6 {
    margin-right: -1.875rem !important;
  }
  .me-xl-n7 {
    margin-right: -2.1875rem !important;
  }
  .me-xl-n8 {
    margin-right: -2.5rem !important;
  }
  .me-xl-n9 {
    margin-right: -2.8125rem !important;
  }
  .me-xl-n10 {
    margin-right: -3.125rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.625rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.25rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -1.625rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -1.875rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -2.1875rem !important;
  }
  .mb-xl-n8 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xl-n9 {
    margin-bottom: -2.8125rem !important;
  }
  .mb-xl-n10 {
    margin-bottom: -3.125rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.3125rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.625rem !important;
  }
  .ms-xl-n3 {
    margin-left: -0.9375rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.25rem !important;
  }
  .ms-xl-n5 {
    margin-left: -1.625rem !important;
  }
  .ms-xl-n6 {
    margin-left: -1.875rem !important;
  }
  .ms-xl-n7 {
    margin-left: -2.1875rem !important;
  }
  .ms-xl-n8 {
    margin-left: -2.5rem !important;
  }
  .ms-xl-n9 {
    margin-left: -2.8125rem !important;
  }
  .ms-xl-n10 {
    margin-left: -3.125rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.3125rem !important;
  }
  .p-xl-2 {
    padding: 0.625rem !important;
  }
  .p-xl-3 {
    padding: 0.9375rem !important;
  }
  .p-xl-4 {
    padding: 1.25rem !important;
  }
  .p-xl-5 {
    padding: 1.625rem !important;
  }
  .p-xl-6 {
    padding: 1.875rem !important;
  }
  .p-xl-7 {
    padding: 2.1875rem !important;
  }
  .p-xl-8 {
    padding: 2.5rem !important;
  }
  .p-xl-9 {
    padding: 2.8125rem !important;
  }
  .p-xl-10 {
    padding: 3.125rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-xl-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-xl-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-xl-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xl-5 {
    padding-right: 1.625rem !important;
    padding-left: 1.625rem !important;
  }
  .px-xl-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-xl-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important;
  }
  .px-xl-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xl-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important;
  }
  .px-xl-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-xl-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-xl-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-xl-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xl-5 {
    padding-top: 1.625rem !important;
    padding-bottom: 1.625rem !important;
  }
  .py-xl-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-xl-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important;
  }
  .py-xl-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xl-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important;
  }
  .py-xl-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.625rem !important;
  }
  .pt-xl-3 {
    padding-top: 0.9375rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.25rem !important;
  }
  .pt-xl-5 {
    padding-top: 1.625rem !important;
  }
  .pt-xl-6 {
    padding-top: 1.875rem !important;
  }
  .pt-xl-7 {
    padding-top: 2.1875rem !important;
  }
  .pt-xl-8 {
    padding-top: 2.5rem !important;
  }
  .pt-xl-9 {
    padding-top: 2.8125rem !important;
  }
  .pt-xl-10 {
    padding-top: 3.125rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.3125rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.625rem !important;
  }
  .pe-xl-3 {
    padding-right: 0.9375rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.25rem !important;
  }
  .pe-xl-5 {
    padding-right: 1.625rem !important;
  }
  .pe-xl-6 {
    padding-right: 1.875rem !important;
  }
  .pe-xl-7 {
    padding-right: 2.1875rem !important;
  }
  .pe-xl-8 {
    padding-right: 2.5rem !important;
  }
  .pe-xl-9 {
    padding-right: 2.8125rem !important;
  }
  .pe-xl-10 {
    padding-right: 3.125rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 1.625rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 1.875rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 2.1875rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 2.8125rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 3.125rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.3125rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.625rem !important;
  }
  .ps-xl-3 {
    padding-left: 0.9375rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.25rem !important;
  }
  .ps-xl-5 {
    padding-left: 1.625rem !important;
  }
  .ps-xl-6 {
    padding-left: 1.875rem !important;
  }
  .ps-xl-7 {
    padding-left: 2.1875rem !important;
  }
  .ps-xl-8 {
    padding-left: 2.5rem !important;
  }
  .ps-xl-9 {
    padding-left: 2.8125rem !important;
  }
  .ps-xl-10 {
    padding-left: 3.125rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.34375rem !important;
  }
  .fs-2 {
    font-size: 1.875rem !important;
  }
  .fs-3 {
    font-size: 1.64063rem !important;
  }
  .fs-4 {
    font-size: 1.40625rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}

/*--------------------------------------
/*  Bootstrap Css End
----------------------------------------*/
/*--------------------------------------
/*   [ 02. Template default CSS ] 
----------------------------------------*/
/*------------------------ 
/* Common Style
 ------------------------*/
/*-----------------------
# selection colors
-------------------------*/
*::-moz-selection {
  background: #fc7418;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #fc7418;
  color: #fff;
  text-shadow: none;
}

button {
  background: transparent;
  border: 0;
  padding: 0;
  outline: 0;
}

a {
  text-decoration: none;
  color: #32383e;
}

a:focus {
  text-decoration: none;
  outline: 0;
}

a:hover {
  text-decoration: none;
  color: #fc7418;
}

img {
  max-width: 100%;
}

:focus {
  outline: 0;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

p {
  font-family: "Roboto", sans-serif;
}

p:last-child {
  margin-bottom: 0;
}

button {
  outline: dotted 0px !important;
}

button:focus {
  box-shadow: none;
}

.btn {
  position: relative;
  z-index: 2;
}

.btn:focus {
  box-shadow: none;
}

@media screen and (max-width: 991px) {
  .btn {
    padding: 18px 18px;
  }
}

@media screen and (max-width: 767px) {
  .btn {
    padding: 14px 18px;
  }
}

.btn-primary {
  border-color: #02126a;
  background-color: #02126a;
  color: #fff;
}

.btn-secondary {
  border-color: #fc7418;
  background-color: #fc7418;
  color: #fff;
}

.btn-success {
  border-color: #32b618;
  background-color: #32b618;
  color: #fff;
}

.btn-info {
  border-color: #0dcaf0;
  background-color: #0dcaf0;
  color: #fff;
}

.btn-warning {
  border-color: #fc7418;
  background-color: #fc7418;
  background-image: linear-gradient(to right, #fc7318, #fe8415, #fe9515, #fea51a, #feb423);
  border: 0px;
  color: #fff;
}

.btn-warning:hover {
  background: linear-gradient(to left, #fc7318, #fe8415, #fe9515, #fea51a, #feb423);
  border-color: #fc7418;
  color: #fff;
}

.btn-danger {
  border-color: #dc3545;
  background-color: #dc3545;
  color: #fff;
}

.btn-light {
  border-color: #f8f8f8;
  background-color: #f8f8f8;
  color: #fff;
}

.btn-dark {
  border-color: #32383e;
  background-color: #32383e;
  color: #fff;
}

@media screen and (min-width: 992px) and (max-width: 1500px) {
  .btn-lg, .btn-group-lg > .btn {
    font-size: 14px;
    padding: 20px 25px;
  }
}

.text-gradient {
  background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-py {
  padding: 40px 0px;
}

@media screen and (min-width: 1200px) {
  .section-py {
    padding: 50px 0px;
  }
}

.section-pt-100 {
  padding-top: 100px;
}

.section-pt {
  padding-top: 40px;
}

@media screen and (min-width: 1200px) {
  .section-pt {
    padding-top: 50px;
  }
}

.section-pb {
  padding-bottom: 40px;
}

@media screen and (min-width: 1200px) {
  .section-pb {
    padding-bottom: 50px;
  }
}

.section-pt-150 {
  padding-top: 150px;
}

@media screen and (max-width: 1199px) {
  .section-pt-150 {
    padding-top: 100px;
  }
}

.section-pb-150 {
  padding-bottom: 150px;
}

@media screen and (max-width: 1199px) {
  .section-pb-150 {
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 1200px) {
  .section-padding-y-200 {
    padding: 200px 0px;
  }
}

@media screen and (max-width: 1199px) {
  .section-padding-y-200 {
    padding: 150px 0px;
  }
}

.section-mt {
  margin-top: 40px;
}

@media screen and (min-width: 1200px) {
  .section-mt {
    margin-top: 50px;
  }
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-60 {
  padding-bottom: 60px;
}

@media screen and (max-width: 1199px) {
  .section-title {
    padding-bottom: 50px;
  }
}

.section-title .icon {
  margin: 0 auto 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.section-title .sub-title {
  font-weight: 700;
  line-height: 1.292;
  text-transform: uppercase;
  font-size: 20px;
  background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
}

.section-title .sub-title::after {
  content: "";
  border-radius: 50%;
  background-color: #eef3ff;
  position: absolute;
  left: 0px;
  top: -20px;
  width: 64px;
  height: 64px;
  z-index: -1;
}

@media screen and (min-width: 1200px) {
  .section-title .sub-title::after {
    left: -20px;
  }
}

.section-title .title {
  font-weight: 700;
  font-size: 55px;
  line-height: 1.545;
  margin-bottom: 5px;
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .section-title .title {
    font-size: 45px;
  }
}

@media screen and (max-width: 767px) {
  .section-title .title {
    font-size: 30px;
  }
}

.section-title.primary .icon {
  background: #fff;
  box-shadow: 0px 0px 55px 0px rgba(2, 18, 106, 0.1);
}

.section-title.primary .title {
  color: #02126a;
}

.section-title.process .icon {
  background: #2843b2;
  box-shadow: 0px 0px 55px 0px rgba(2, 18, 106, 0.1);
}

.section-title.process .title {
  color: #fff;
}

.section-title .hr-secodary {
  position: relative;
  background-image: -moz-linear-gradient(0deg, #fc7318 0%, #feb423 100%);
  background-image: -webkit-linear-gradient(0deg, #fc7318 0%, #feb423 100%);
  background-image: -ms-linear-gradient(0deg, #fc7318 0%, #feb423 100%);
  width: 30px;
  height: 5px;
  display: inline-block;
  border-radius: 5px;
}

.section-title .hr-secodary::before, .section-title .hr-secodary::after {
  width: 15px;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  background: #e6e9f5;
  border-radius: 5px;
}

.section-title .hr-secodary::before {
  left: -30px;
}

.section-title .hr-secodary::after {
  right: -30px;
}

.section-title .hr-primary {
  position: relative;
  margin-left: 25px;
  background-image: -moz-linear-gradient(0deg, #fc7318 0%, #feb423 100%);
  background-image: -webkit-linear-gradient(0deg, #fc7318 0%, #feb423 100%);
  background-image: -ms-linear-gradient(0deg, #fc7318 0%, #feb423 100%);
  width: 30px;
  height: 5px;
  display: inline-block;
  border-radius: 5px;
}

.section-title .hr-primary::before, .section-title .hr-primary::after {
  width: 15px;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  background: #02126a;
  border-radius: 5px;
}

.section-title .hr-primary::before {
  left: -25px;
}

.section-title .hr-primary::after {
  right: -25px;
}

#scrollUp {
  right: 30px;
  bottom: 40px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-size: 25px;
  background-image: linear-gradient(to right, #fc7318, #fe8415, #fe9515, #fea51a, #feb423);
  text-align: center;
  border-radius: 50%;
}

@media screen and (min-width: 768px) {
  #scrollUp {
    right: 50px;
  }
}

#scrollUp:hover {
  background: linear-gradient(to left, #fc7318, #fe8415, #fe9515, #fea51a, #feb423);
}

.btn-check:checked + .btn,
.btn-check:active + .btn, .btn:active, .btn.active, .btn:focus {
  color: #fff;
}

/*--------------------------------------
/*   [ 03. header CSS ] 
----------------------------------------*/
/*------------------------------------- 
 # offcanvas-form
 -------------------------------------*/
.offcanvas-form {
  max-width: 970px;
  margin: 0 auto;
}

.offcanvas-form .form-control {
  height: 55px;
  border: 1px solid #273581;
  padding-left: 15px;
  padding-right: 95px;
  border-radius: 8px;
  font-size: 14px;
  color: #32383e;
}

.offcanvas-form .form-control::placeholder {
  color: #32383e;
}

.offcanvas-form .btn {
  position: absolute;
  top: 5px;
  right: 5px;
}

.offcanvas-form .btn.btn-warning {
  padding: 15px 20px;
  font-size: 15px;
}

/*------------------------------------- 
 # Offcanvas Menu
 -------------------------------------*/
.offcanvas-toggler {
  text-align: center;
  display: inline-block;
  padding: 0px !important;
  width: 50px;
  height: 50px;
  z-index: 3;
  margin-left: 30px;
  border-radius: 50%;
}

@media screen and (min-width: 992px) {
  .offcanvas-toggler {
    display: none;
  }
}

.offcanvas-toggler .line {
  position: relative;
  display: block;
  width: 25px;
  height: 2px;
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.3s;
}

.offcanvas-toggler .line + .line {
  margin-top: 4px;
}

.offcanvas-toggler:hover .line:nth-child(1) {
  transform: rotate(45deg);
  top: 7px;
}

.offcanvas-toggler:hover .line:nth-child(2) {
  opacity: 0;
  visibility: hidden;
}

.offcanvas-toggler:hover .line:nth-child(3) {
  transform: rotate(-46deg);
  bottom: 5px;
}

.offcanvas-modal.modal.fade .modal-dialog {
  transform: translateX(-100%);
}

.offcanvas-modal.modal.show .modal-dialog {
  transform: translateX(0%);
}

.offcanvas-dialog {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 100%;
  margin: 0;
}

@media screen and (min-width: 576px) {
  .offcanvas-dialog {
    width: 400px;
  }
}

.offcanvas-dialog .modal-content {
  height: 100%;
  border-radius: 0;
  border: 0;
}

.offcanvas-header {
  border-bottom: 1px solid #273581;
}

.offcanvas-menu {
  position: relative;
  z-index: 1;
  overflow-y: scroll;
}

.offcanvas-menu::before {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background-color: #fff;
}

.offcanvas-menu ul ul {
  display: none;
}

.offcanvas-menu > ul > li > ul li {
  border-bottom: 0px;
}

.offcanvas-menu > ul > li > ul li a {
  padding-left: 15px;
  font-weight: 400;
  font-size: 14px;
}

.offcanvas-menu li.active > ul {
  display: block;
}

.offcanvas-menu li a {
  font-weight: 700;
  display: block;
  text-transform: capitalize;
  color: #02126a;
  padding: 10px 0px;
  position: relative;
  font-size: 15px;
}

.offcanvas-menu li a:hover {
  color: #fc7418;
}

.offcanvas-menu a:not(:only-child):after {
  font-family: IcoFont;
  content: "\efc2";
  position: absolute;
  right: 15px;
  top: 15px;
}

.offcanvas-menu .active > a:not(:only-child):after {
  content: "\ef9a";
}

/******************************** 
# Offcanvas Menu Style End
********************************/
.offcanvas-social {
  margin-top: 30px;
  margin-bottom: 10px;
}

.offcanvas-social li {
  display: inline-block;
  margin: 0 10px 10px 0;
  line-height: 40px;
  padding: 0;
}

.offcanvas-social li a {
  display: inline-block;
  color: #fff;
  background: #02126a;
  font-size: 15px;
  line-height: 45px;
  width: 45px;
  height: 45px;
  text-align: center;
  padding: 0px;
  transition: none;
  border-radius: 100%;
}

.offcanvas-social li a:hover {
  background-image: linear-gradient(to right, #fc7318, #fe8415, #fe9515, #fea51a, #feb423);
  color: #fff;
}

.offcanvas-modal .modal-content {
  padding: 0px 15px;
}

@media screen and (min-width: 576px) {
  .offcanvas-modal .modal-content {
    padding: 0px 30px;
  }
}

.offcanvas-modal .offcanvas-form {
  margin: 20px 0px;
}

/*--------------------------------------
/*   [ 04. header top CSS ] 
----------------------------------------*/
@media screen and (min-width: 1920px) {
  .header .container {
    max-width: 1370px;
  }
}

.header-top p {
  font-family: "Ruda", sans-serif;
  font-weight: 400;
  color: #fff;
}

.header-top li {
  color: #fff;
}

.header-top li a {
  color: #fff;
}

.header-social-links .social-link-item {
  border-right: 1px solid #273581;
}

.header-social-links .social-link-item:first-child {
  border-left: 1px solid #273581;
}

.header-social-links .social-link-item .social-link {
  display: block;
  padding: 22px 20px;
  line-height: 1;
}

.header-social-links .social-link-item .social-link:hover {
  background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hr-border {
  background-image: -moz-linear-gradient(0deg, #fc7318 0%, #feb423 100%);
  background-image: -webkit-linear-gradient(0deg, #fc7318 0%, #feb423 100%);
  background-image: -ms-linear-gradient(0deg, #fc7318 0%, #feb423 100%);
  display: inline-block;
  width: 90px;
  height: 1px;
  margin-right: 30px;
}

.select-box .select-item {
  line-height: 1;
  position: relative;
}

.select-box .select-item:first-child {
  padding: 22px 20px;
  border-left: 1px solid #273581;
}

.select-box .select-item a:hover {
  background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.select-box .select-item .selectric {
  border: 0px;
  background: transparent;
}

.select-box .select-item .selectric .label {
  color: #fff;
  font-size: 15px;
}

.select-box .select-item .selectric .button {
  background: transparent;
}

.select-box .select-item .selectric .button::after {
  top: 4px;
  width: 15px;
  height: 15px;
  content: " ";
  background-image: url("../images/icon/arrow-down-white.png");
  background-repeat: no-repeat;
  border: 0px solid transparent;
}

.select-box .selectric-items {
  background: #fff;
  border: 0px;
  box-shadow: 0px 22px 35px 0px rgba(2, 18, 106, 0.08);
}

.select-box .selectric-items li.highlighted {
  background-image: linear-gradient(to right, #fc7318, #fe8415, #fe9515, #fea51a, #feb423);
  color: #fff;
  font-size: 14px;
}

.select-box .selectric-items ul,
.select-box .selectric-items li {
  font-size: 14px;
  color: #02126a;
}

.select-box .selectric-items li:hover {
  background: #02126a;
  color: #fff;
}

.select-box .form-select {
  padding: 22px 45px 20px 22px;
  color: #fff;
  border: 0px;
  border-left: 1px solid #273581;
  border-right: 1px solid #273581;
  background: transparent;
  line-height: 1.1;
  border-radius: 0px;
  cursor: pointer;
}

.select-box .form-select:focus {
  box-shadow: none !important;
}

.select-box .form-select:hover {
  background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.select-box .form-select:focus-visible {
  text-shadow: none;
}

/*--------------------------------------
/*   [ 05. main menu CSS ] 
----------------------------------------*/
.header-bottom {
  padding: 12px 0px;
}

.main-menu .btn-warning {
  margin-left: 20px;
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .main-menu .btn-warning {
    margin-left: 0px;
  }
}

.main-menu .btn-warning i {
  margin-left: 15px;
}

.main-menu-item {
  position: relative;
  display: inline-block;
}

.main-menu-item + .main-menu-item {
  margin-left: 60px;
}

@media screen and (min-width: 1201px) and (max-width: 1500px) {
  .main-menu-item + .main-menu-item {
    margin-left: 45px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .main-menu-item + .main-menu-item {
    margin-left: 25px;
  }
}

.main-menu-link {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 700;
  line-height: 1;
  font-size: 16px;
  color: #02126a;
}

.main-menu-link:hover {
  color: #fc7418;
}

.is-sticky {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  padding: 10px 0px;
  background: #fff;
  transition: all 0.3s;
  animation: fadeInDown 0.5s ease-in-out;
  box-shadow: 0px 3px 11.16px 0.84px rgba(0, 0, 0, 0.1);
}

.is-sticky .btn-warning {
  padding: 20px 25px;
}

@media screen and (max-width: 767px) {
  .is-sticky .btn-warning {
    padding: 14px 18px;
  }
}

/*--------------------------------------
/*   [ 06. sub menu CSS ] 
----------------------------------------*/
.sub-menu {
  position: absolute;
  z-index: 9999;
  top: 100%;
  left: 0;
  visibility: hidden;
  width: 230px;
  margin-top: 30px;
  margin-bottom: 0;
  padding: 18px 0;
  opacity: 0;
  border-bottom: 4px solid #02126a;
  background-color: #fff;
  box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
  transition: all 0.4s ease 0.2s;
}

.sub-menu li + li {
  margin-left: 0px;
}

.sub-menu .sub-menu-link {
  text-transform: capitalize;
  color: #02126a;
  text-align: left;
  font-size: 15px;
  line-height: 1.375;
  display: block;
  padding: 8px 30px;
}

.sub-menu .sub-menu-link:hover {
  color: #fc7418;
}

.main-menu li:hover .sub-menu {
  visibility: visible;
  margin-top: 15px;
  opacity: 1;
}

/*--------------------------------------
/*   [ 07. hero section CSS ] 
----------------------------------------*/
.hero-section {
  margin-bottom: 250px;
  position: relative;
  border-top: 1px solid #f0f0f0;
}

@media screen and (max-width: 991px) {
  .hero-section {
    margin-bottom: 0px;
  }
}

.hero-content {
  padding-top: 60px;
  position: relative;
}

@media screen and (min-width: 1200px) {
  .hero-content {
    padding-top: 160px;
  }
}

.hero-content .title {
  margin-bottom: 20px;
  color: #02126a;
  font-weight: 900;
  line-height: 1.133;
  font-size: 75px;
}

@media screen and (min-width: 1200px) and (max-width: 1499px) {
  .hero-content .title {
    font-size: 65px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-content .title {
    font-size: 45px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .hero-content .title {
    font-size: 55px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .hero-content .title {
    font-size: 40px;
  }
}

@media screen and (max-width: 575px) {
  .hero-content .title {
    font-size: 32px;
  }
}

.hero-content p {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  color: #5c6179;
  line-height: 1.473;
  font-size: 19px;
}

@media screen and (min-width: 768px) and (max-width: 1500px) {
  .hero-content p {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  .hero-content p {
    font-size: 15px;
  }
}

.hero-content p span {
  margin-right: 20px;
  display: inline-block;
  background-image: -moz-linear-gradient(0deg, #fc7318 0%, #feb423 100%);
  background-image: -webkit-linear-gradient(0deg, #fc7318 0%, #feb423 100%);
  background-image: -ms-linear-gradient(0deg, #fc7318 0%, #feb423 100%);
  width: 50px;
  height: 3px;
}

.hero-img {
  animation: move 1.5s infinite alternate;
}

@media screen and (max-width: 991px) {
  .hero-img {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .hero-img {
    position: absolute;
    right: 165px;
    top: 40px;
  }
}

@media screen and (min-width: 1920px) {
  .hero-img {
    max-width: 683px;
  }
}

@media screen and (min-width: 1800px) and (max-width: 1919px) {
  .hero-img {
    max-width: 650px;
    right: 160px;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1799px) {
  .hero-img {
    max-width: 550px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1499px) {
  .hero-img {
    max-width: 450px;
    right: 160px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-img {
    max-width: 340px;
    right: 140px;
  }
}

@media screen and (min-width: 991px) {
  .hero-img-mobile {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .hero-img-mobile {
    margin-top: 150px;
    max-width: 700px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 767px) {
  .hero-img-mobile {
    margin-top: 80px;
  }
}

@media screen and (max-width: 480px) {
  .hero-img-mobile {
    margin-top: 0px;
  }
}

.hero-form {
  max-width: 470px;
  margin-bottom: 80px;
  z-index: 1;
}

.hero-form .form-control {
  background-color: #fff;
  height: 68px;
  border: 1px solid #eef3ff;
  padding-left: 30px;
  padding-right: 200px;
  border-radius: 8px;
}

.hero-form .btn {
  position: absolute;
  top: 6px;
  right: 6px;
}

.hero-form .btn.btn-warning {
  padding: 20px 25px;
  font-size: 16px;
}

.animate-two {
  left: -160px;
  bottom: -60px;
  animation: move 0.6s infinite alternate;
}

@media screen and (min-width: 1800px) and (max-width: 1919px) {
  .animate-two {
    max-width: 400px;
    left: -100px;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1799px) {
  .animate-two {
    max-width: 400px;
    left: -100px;
    bottom: -200px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1499px) {
  .animate-two {
    max-width: 350px;
    left: -100px;
    bottom: -180px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .animate-two {
    max-width: 280px;
    left: -80px;
    bottom: -150px;
  }
}

.animate-three {
  right: -160px;
  bottom: -60px;
  animation: move 0.9s infinite alternate;
}

@media screen and (min-width: 1800px) and (max-width: 1919px) {
  .animate-three {
    max-width: 300px;
    bottom: 0;
    right: -100px;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1799px) {
  .animate-three {
    max-width: 330px;
    bottom: -90px;
    right: -160px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1499px) {
  .animate-three {
    max-width: 290px;
    bottom: -90px;
    right: -150px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .animate-three {
    max-width: 230px;
    bottom: -90px;
    right: -130px;
  }
}

.shape {
  position: absolute;
}

.shape1 {
  top: 0;
  left: 0;
  z-index: -1;
}

.shape2 {
  top: 0;
  right: 0;
  z-index: -2;
}

.particle1 {
  top: 195px;
  left: 90px;
  z-index: -1;
  animation: move 0.6s infinite alternate;
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .particle1 {
    max-width: 80px;
  }
}

@media screen and (max-width: 1199px) {
  .particle1 {
    max-width: 70px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .particle1 {
    top: 50px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .particle1 {
    top: 50px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .particle1 {
    top: 20px;
  }
}

@media screen and (max-width: 575px) {
  .particle1 {
    top: 20px;
  }
}

@media screen and (max-width: 991px) {
  .particle1 {
    display: none;
  }
}

.particle2 {
  bottom: -125px;
  left: 200px;
  z-index: -1;
  animation: move 0.9s infinite alternate;
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .particle2 {
    bottom: -180px;
    left: 145px;
    max-width: 200px;
  }
}

@media screen and (min-width: 320px) and (max-width: 991px) {
  .particle2 {
    max-width: 140px;
    left: 250px;
    bottom: auto;
    top: 250px;
  }
}

@media screen and (max-width: 1199px) {
  .particle2 {
    max-width: 140px;
    left: 250px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .particle2 {
    top: 250px;
    left: 50px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .particle2 {
    top: 200px;
    left: 50px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .particle2 {
    top: 150px;
    left: 50px;
  }
}

@media screen and (max-width: 575px) {
  .particle2 {
    top: 140px;
    left: 50px;
  }
}

@media screen and (max-width: 991px) {
  .particle2 {
    display: none;
  }
}

.particle3 {
  position: absolute;
  left: 110px;
  animation: move 1s infinite alternate;
}

@media screen and (max-width: 1199px) {
  .particle3 {
    left: 60px;
    max-width: 80px;
    bottom: -180px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .particle3 {
    left: 200px;
    bottom: -250px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .particle3 {
    left: 200px;
    bottom: -150px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1499px) {
  .particle3 {
    left: 310px;
    max-width: 120px;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1800px) {
  .particle3 {
    left: 200px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .particle3 {
    top: 310px;
    left: 200px;
  }
}

@media screen and (max-width: 575px) {
  .particle3 {
    top: 300px;
    left: 200px;
  }
}

@media screen and (max-width: 991px) {
  .particle3 {
    display: none;
  }
}

@-webkit-keyframes move {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes move {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

/*--------------------------------------
/*   [ 08. brand logo CSS ] 
----------------------------------------*/
.brand-section {
  padding-top: 260px;
}

@media screen and (min-width: 1200px) and (max-width: 1499px) {
  .brand-section {
    padding-top: 150px;
  }
}

@media screen and (max-width: 1199px) {
  .brand-section {
    padding-top: 100px;
  }
}

@media screen and (min-width: 1920px) {
  .brand-section .container {
    max-width: 1400px;
  }
}

.brand-carousel .swiper-slide {
  text-align: center;
}

.brand-card {
  background-color: #02126a;
  background-image: url("../images//brand-logo/bg.png");
  padding: 65px 115px 90px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 35px;
}

@media screen and (max-width: 1499px) {
  .brand-card {
    padding: 65px 0px 90px;
  }
}

@media screen and (max-width: 991px) {
  .brand-card {
    padding: 50px 20px 70px;
  }
}

.brand-card p {
  font-weight: 700;
  line-height: 3.863;
  margin-bottom: 30px;
  color: #fff;
  font-size: 22px;
}

@media screen and (max-width: 767px) {
  .brand-card p {
    line-height: 2;
    font-size: 20px;
  }
}

.brand-card p span {
  font-weight: 900;
  line-height: 2.428;
  font-size: 35px;
}

@media screen and (max-width: 767px) {
  .brand-card p span {
    font-size: 25px;
  }
}

.brand-card .brand-before {
  display: block;
  transition: all 0.4s;
}

.brand-card .brand-after {
  top: 150%;
  position: absolute;
  display: block;
  margin: 0 auto;
  left: 0;
  right: 0;
  transition: all 0.4s;
}

.brand-card .swiper-slide:hover .brand-before {
  transform: translateY(-100%);
}

.brand-card .swiper-slide:hover .brand-after {
  top: 0%;
}

/*--------------------------------------
/*   [ 09. service card CSS ] 
----------------------------------------*/
.service-card {
  display: flex;
  position: relative;
  background: #f7faff;
  padding: 40px 40px 40px 50px;
  border-radius: 15px;
}

@media screen and (max-width: 1199px) {
  .service-card {
    flex-direction: column;
  }
}

.service-card .line {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.service-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: 0px 22px 35px 0px rgba(2, 18, 106, 0.08);
  width: 115px;
  height: 115px;
  margin-right: 50px;
  border-radius: 50%;
}

@media screen and (max-width: 1199px) {
  .service-icon {
    width: 100px;
    height: 100px;
  }
}

@media screen and (max-width: 1199px) {
  .service-icon {
    margin-bottom: 40px;
  }
}

.service-icon .roted-around {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.service-icon .roted-around span {
  position: relative;
  display: block;
  height: 100%;
}

.service-icon .roted-around span::before, .service-icon .roted-around span::after {
  position: absolute;
  width: 8px;
  height: 8px;
  content: "";
  border-radius: 50%;
}

.service-icon .roted-around span::before {
  left: 15px;
  bottom: 10px;
}

.service-icon .roted-around span::after {
  right: 15px;
  top: 10px;
}

.service-icon .roted-around.dagnger span {
  animation: rotation 10s infinite linear;
}

.service-icon .roted-around.dagnger span::before, .service-icon .roted-around.dagnger span::after {
  background-image: -moz-linear-gradient(-45deg, #fd4485 0%, #ffa38d 100%);
  background-image: -webkit-linear-gradient(-45deg, #fd4485 0%, #ffa38d 100%);
  background-image: -ms-linear-gradient(-45deg, #fd4485 0%, #ffa38d 100%);
}

.service-icon .roted-around.warning span {
  animation: rotation 9s infinite linear;
}

.service-icon .roted-around.warning span::before, .service-icon .roted-around.warning span::after {
  background-image: -moz-linear-gradient(-45deg, #fd6300 0%, #ffe121 100%);
  background-image: -webkit-linear-gradient(-45deg, #fd6300 0%, #ffe121 100%);
  background-image: -ms-linear-gradient(-45deg, #fd6300 0%, #ffe121 100%);
}

.service-icon .roted-around.primary span {
  animation: rotation 8s infinite linear;
}

.service-icon .roted-around.primary span::before, .service-icon .roted-around.primary span::after {
  background-image: -moz-linear-gradient(-45deg, #505eff 0%, #03bdff 100%);
  background-image: -webkit-linear-gradient(-45deg, #505eff 0%, #03bdff 100%);
  background-image: -ms-linear-gradient(-45deg, #505eff 0%, #03bdff 100%);
}

.service-icon .roted-around.secondary span {
  animation: rotation 7s infinite linear;
}

.service-icon .roted-around.secondary span::before, .service-icon .roted-around.secondary span::after {
  background-image: -moz-linear-gradient(-45deg, #b241ff 0%, #fe86ff 100%);
  background-image: -webkit-linear-gradient(-45deg, #b241ff 0%, #fe86ff 100%);
  background-image: -ms-linear-gradient(-45deg, #b241ff 0%, #fe86ff 100%);
}

.service-content {
  flex: 1;
}

.service-content .title {
  color: #02126a;
  font-weight: 700;
  line-height: 0.8;
  margin-bottom: 25px;
  font-size: 25px;
}

@media screen and (max-width: 1199px) {
  .service-content .title {
    font-size: 20px;
  }
}

.service-content p {
  margin-bottom: 30px;
}

.btn-outline-danger {
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #fd4485 0%, #ffa38d 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #f7faff inset;
  color: #02126a;
}

.btn-outline-danger:hover {
  box-shadow: none;
  color: #fff;
  border-color: transparent;
}

.btn-outline-warning {
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #fd6300 0%, #ffe121 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #f7faff inset;
  color: #02126a;
}

.btn-outline-warning:hover {
  box-shadow: none;
  color: #fff;
  border-color: transparent;
}

.btn-outline-primary {
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #505eff 0%, #03bdff 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #f7faff inset;
  color: #02126a;
}

.btn-outline-primary:hover {
  box-shadow: none;
  color: #fff;
  border-color: transparent;
}

.btn-outline-secondary {
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #b241ff 0%, #fe86ff 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #f7faff inset;
  color: #02126a;
}

.btn-outline-secondary:hover {
  box-shadow: none;
  color: #fff;
  border-color: transparent;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

/*--------------------------------------
/*   [ 10. working process CSS ] 
----------------------------------------*/
.working-process-section {
  background-image: url("../images/working/bg.png");
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 130px 0px 115px;
}

@media screen and (max-width: 1199px) {
  .working-process-section {
    padding: 100px 0px 100px;
  }
}

@media screen and (min-width: 1400px) {
  .working-process-section .container {
    max-width: 1200px;
  }
}

.working-process .arrow-shape {
  position: absolute;
  top: -10px;
  right: -30px;
}

@media screen and (max-width: 991px) {
  .working-process .arrow-shape {
    top: 30px;
  }
}

@media screen and (max-width: 575px) {
  .working-process .arrow-shape {
    display: none;
  }
}

.working-process .working-process-list {
  position: relative;
  text-align: center;
}

.working-process .working-process-list .icon {
  margin: 0 auto 5px;
  position: relative;
  border: 5px solid #132580;
  border-radius: 50%;
  background-color: #02126a;
  width: 170px;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .working-process .working-process-list .icon {
    width: 150px;
    height: 150px;
  }
}

@media screen and (max-width: 991px) {
  .working-process .working-process-list .icon {
    width: 140px;
    height: 140px;
  }
}

.working-process .working-process-list .icon::before {
  position: absolute;
  left: -8px;
  top: -8px;
  content: "";
  background-image: url("../images/working/circle.png");
  width: 110%;
  height: 110%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
}

.working-process .working-process-list:hover .icon::before {
  animation: rotation 5s infinite linear;
  opacity: 1;
  visibility: visible;
}

.working-process .working-process-list .title {
  font-weight: 700;
  color: #fff;
  line-height: 2.833;
  margin-bottom: 0;
  font-size: 30px;
  transition: all 0.3s;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .working-process .working-process-list .title {
    font-size: 25px;
  }
}

@media screen and (max-width: 991px) {
  .working-process .working-process-list .title {
    font-size: 20px;
  }
}

.working-process .working-process-list:hover .title {
  background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.working-process .working-process-list:nth-child(odd) {
  margin-top: 125px;
}

@media screen and (max-width: 991px) {
  .working-process .working-process-list:nth-child(odd) {
    margin-top: 0px;
  }
}

.working-process .working-process-list:nth-child(even) .arrow-shape {
  top: 125px;
  right: -40px;
}

@media screen and (max-width: 991px) {
  .working-process .working-process-list:nth-child(even) .arrow-shape {
    display: none;
  }
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

/*--------------------------------------
/*   [ 11. about us CSS ] 
----------------------------------------*/
.about-section {
  position: relative;
  padding: 190px 0px;
}

@media screen and (max-width: 1199px) {
  .about-section {
    padding: 100px 0px;
  }
}

@media screen and (min-width: 1200px) {
  .about-section .container {
    max-width: 100%;
  }
}

@media screen and (min-width: 1500px) {
  .about-section .container {
    max-width: 1370px;
  }
}

@media screen and (min-width: 1500px) {
  .about-section .container .offset-about {
    margin-left: 70px;
  }
}

.about-section2 {
  position: relative;
  padding-bottom: 100px;
}

.about-section2 .container {
  position: relative;
  z-index: 2;
}

.about-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.about-bg2 {
  position: absolute;
  top: -260px;
  right: 0;
  z-index: -1;
}

.about-img-content {
  padding-top: 40px;
}

@media screen and (max-width: 991px) {
  .about-img-content {
    margin-bottom: 50px;
  }
}

.about-img-content img {
  animation: move 0.8s infinite alternate;
}

.about-img-content2 {
  margin-top: 40px;
}

@media screen and (min-width: 1500px) {
  .about-img-content2 {
    margin-right: -140px;
  }
}

@media screen and (min-width: 1800px) {
  .about-img-content2 {
    margin-right: -240px;
  }
}

.about-object {
  margin-top: 40px;
  margin-bottom: -25px;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .about-object {
    margin-top: 100px;
    margin-left: -350px;
    max-width: 700px;
  }
}

@media screen and (max-width: 991px) {
  .about-object {
    margin-top: 0px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .about-section2 .about-object {
    margin-top: 0px;
    margin-left: 0px;
  }
}

@media screen and (max-width: 991px) {
  .about-section2 .about-object {
    margin-top: 0px;
  }
}

.about-object-list {
  margin-bottom: 25px;
  display: flex;
}

@media screen and (max-width: 575px) {
  .about-object-list {
    flex-direction: column;
  }
}

.about-object-list .icon {
  background-color: white;
  box-shadow: 0px 15px 30px 0px rgba(2, 18, 106, 0.05);
  width: 85px;
  height: 85px;
  margin-right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

@media screen and (max-width: 991px) {
  .about-object-list .icon {
    width: 70px;
    height: 70px;
  }
}

@media screen and (max-width: 575px) {
  .about-object-list .icon {
    margin-bottom: 40px;
  }
}

.about-object-content {
  flex: 1;
}

.about-object-content .title {
  color: #02126a;
  font-weight: 700;
  line-height: 1.227;
  text-transform: capitalize;
  margin-bottom: 15px;
  font-size: 22px;
}

@media screen and (max-width: 991px) {
  .about-object-content .title {
    font-size: 20px;
  }
}

.aboutus-section {
  padding: 150px 0px;
}

@media screen and (max-width: 1199px) {
  .aboutus-section {
    padding: 100px 0px;
  }
}

.about-thumb {
  padding: 75px 10px;
  border: 1px solid #eaedf9;
  background-color: #ffffff;
  border-radius: 15px;
  text-align: center;
}

.about-thumb img {
  border-radius: inherit;
}

.about-cards {
  margin-bottom: -30px;
}

.about-card-list {
  background-color: #f7faff;
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 15px;
}

.about-card-list .sub-title {
  font-weight: 700;
  line-height: 2.8;
  font-size: 15px;
  margin-bottom: 0px;
}

.about-card-list .title {
  font-weight: 700;
  line-height: 1.4;
  color: #02126a;
  margin-bottom: 20px;
  font-size: 28px;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .about-card-list .title {
    font-size: 25px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .about-card-list .title {
    font-size: 22px;
  }
}

@media screen and (max-width: 767px) {
  .about-card-list .title {
    font-size: 20px;
  }
}

.about-card-list .read-more {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #fc7418;
}

.about-card-list .read-more:hover {
  text-decoration: underline;
}

.about-banner {
  position: relative;
  padding: 60px 20px;
  background-image: url("../images/about/3.png");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  z-index: 1;
  border-radius: 15px;
}

.about-banner::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-image: -moz-linear-gradient(90deg, #02126a 0%, #2647c8 100%);
  background-image: -webkit-linear-gradient(90deg, #02126a 0%, #2647c8 100%);
  background-image: -ms-linear-gradient(90deg, #02126a 0%, #2647c8 100%);
  opacity: 0.9;
  z-index: -1;
}

.about-banner .logo {
  display: block;
  margin-bottom: 35px;
}

.about-banner .title {
  color: #fff;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 20px;
  font-size: 30px;
}

@media screen and (max-width: 767px) {
  .about-banner .title {
    font-size: 20px;
  }
}

.about-banner p {
  color: #fc7418;
}

.about-banner p a {
  color: #fff;
}

@media screen and (min-width: 1200px) {
  .about-content .title {
    font-size: 50px;
  }
}

/*--------------------------------------
/*   [ 12. case studies CSS ] 
----------------------------------------*/
.case-studies-section {
  padding-bottom: 60px;
}

@media screen and (min-width: 1800px) {
  .case-studies-section .container {
    max-width: 1620px;
  }
}

.case-carousel .swiper-slide-active .case-card .case-shape1 {
  opacity: 0;
}

.case-carousel .swiper-slide-active .case-card .case-shape2 {
  opacity: 1;
}

.case-carousel .swiper-slide-active .case-card .case-content {
  opacity: 1;
  visibility: visible;
}

.case-card .thumb {
  position: relative;
  overflow: hidden;
  border-radius: 25px;
  background-color: #f7faff;
  margin-bottom: 40px;
}

.case-card .thumb .case-shape1 {
  transition: all 0.3s;
}

.case-card .thumb .case-shape2 {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.3s;
}

.case-card .thumb .case-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.case-card .thumb a {
  display: block;
}

.case-card .thumb a img {
  z-index: 2;
}

.case-content {
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
  text-align: center;
}

.case-content .title {
  font-weight: 700;
  color: #02126a;
  line-height: 1.25;
  text-transform: capitalize;
  font-size: 25px;
  margin-bottom: 15px;
}

.case-content .title a {
  color: inherit;
}

.case-content .title a:hover {
  color: #fc7418;
}

.case-content p {
  color: #fc7418;
}

.container.case-carousel-navigation {
  position: relative;
  top: -122px;
}

@media screen and (min-width: 1200px) {
  .container.case-carousel-navigation {
    max-width: 1140px;
  }
}

.container.case-carousel-navigation .swiper-button-prev,
.container.case-carousel-navigation .swiper-button-next {
  width: 55px;
  height: 55px;
  color: #fff;
  font-size: 20px;
  border-radius: 50%;
}

.container.case-carousel-navigation .swiper-button-prev {
  background: linear-gradient(to right, #fc7318, #fe8415, #fe9515, #fea51a, #feb423);
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #fc7318 0%, #feb423 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #f7faff inset;
  color: #fc7418;
}

.container.case-carousel-navigation .swiper-button-prev:hover {
  box-shadow: none;
  color: #fff;
  border-color: transparent;
}

.container.case-carousel-navigation .swiper-button-next {
  background: linear-gradient(to right, #fc7318, #fe8415, #fe9515, #fea51a, #feb423);
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #fc7318 0%, #feb423 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #f7faff inset;
  color: #fc7418;
}

.container.case-carousel-navigation .swiper-button-next:hover {
  box-shadow: none;
  color: #fff;
  border-color: transparent;
}

.container.case-carousel-navigation .swiper-button-prev::after,
.container.case-carousel-navigation .swiper-container-rtl .swiper-button-next::after {
  display: none;
}

.container.case-carousel-navigation .swiper-button-next::after,
.container.case-carousel-navigation .swiper-container-rtl .swiper-button-next::after {
  display: none;
}

/*--------------------------------------
/*   [ 13. team CSS ] 
----------------------------------------*/
.team-section {
  padding: 290px 0px;
  background-image: linear-gradient(to right, #03136b, #0d1e7f, #162993, #1c35a8, #2241be);
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .team-section {
    padding: 180px 0px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .team-section {
    padding: 125px 0px;
  }
}

@media screen and (max-width: 767px) {
  .team-section {
    padding: 100px 0px;
  }
}

.team-section::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  content: "";
  background-image: url("../images/team/shape-top.png");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 11%;
}

.team-section::before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  content: "";
  background-image: url("../images/team/shape-bottom.png");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 11%;
}

.team-section .pattern {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.team-card {
  text-align: center;
  overflow: hidden;
}

.team-card .thumb {
  position: relative;
  margin-bottom: 40px;
}

.team-card .thumb .social-hover {
  position: absolute;
  top: 30px;
  left: 30px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
  animation: rotation 5s infinite linear;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .team-card .thumb .social-hover {
    top: 15px;
    left: 0;
    right: 0;
    display: block;
    margin: 0 auto;
  }
}

@media screen and (min-width: 576px) and (max-width: 991px) {
  .team-card .thumb .social-hover {
    top: 30px;
    left: 0;
    right: 0;
    display: block;
    margin: 0 auto;
  }
}

@media screen and (max-width: 575px) {
  .team-card .thumb .social-hover {
    top: 30px;
    left: 0;
    right: 0;
    display: block;
    margin: 0 auto;
  }
}

.team-card:hover .social-hover {
  opacity: 1;
  visibility: visible;
}

.team-card .title {
  font-weight: 700;
  color: #fff;
  text-transform: capitalize;
  line-height: 0.8;
  font-size: 25px;
}

.team-card p {
  font-weight: 700;
  line-height: 2.076;
  font-size: 13px;
  text-transform: uppercase;
  color: #fc7418;
}

.team-card .team-social {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
}

.team-card .team-social-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: linear 0.3s;
}

.team-card .team-social-link {
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  width: 39px;
  height: 39px;
  color: #fff;
  border-radius: 50%;
}

.team-card .team-social-link:hover {
  background: #fff;
  color: #02126a;
}

.team-card:hover .team-social {
  opacity: 1;
  visibility: visible;
}

.team-card:hover .team-social-item:nth-child(1) {
  top: 85px;
  left: 50%;
  transform: translateX(-50%);
}

.team-card:hover .team-social-item:nth-child(2) {
  top: 130px;
  left: 40%;
  transform: translateX(-50%);
}

.team-card:hover .team-social-item:nth-child(3) {
  top: 130px;
  left: 60%;
  transform: translateX(-50%);
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

/*--------------------------------------
/*   [ 14. faq CSS ] 
----------------------------------------*/
.faq-section {
  padding-top: 50px;
  padding-bottom: 140px;
  position: relative;
}

@media screen and (max-width: 991px) {
  .faq-section {
    padding-top: 0px;
    padding-bottom: 100px;
  }
}

.faq-bg {
  position: absolute;
  top: -170px;
  left: 0;
  z-index: -1;
}

@media screen and (max-width: 991px) {
  .faq-bg {
    top: -30px;
  }
}

@media screen and (max-width: 767px) {
  .faq-bg {
    top: 0px;
  }
}

@media screen and (min-width: 1500px) {
  .faq-image {
    margin-left: -180px;
  }
}

@media screen and (max-width: 1499px) {
  .faq-image {
    text-align: center;
  }
}

.faq-image img {
  animation: move 1s infinite alternate;
}

@media screen and (max-width: 991px) {
  .faq-image {
    margin-top: 50px;
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1500px) {
  .faq-content {
    margin-left: 80px;
  }
}

/*--------------------------------------
/*   [ 15. accordion CSS ] 
----------------------------------------*/
.accordion {
  margin-top: 40px;
  margin-bottom: -10px;
}

@media screen and (max-width: 991px) {
  .accordion {
    margin-top: 0px;
  }
}

.accordion.accordion2 {
  margin-top: 0;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 19px 40px;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
  font-size: 18px;
  border: 0;
  border-radius: 15px;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}

@media screen and (max-width: 767px) {
  .accordion-button {
    padding: 19px 19px;
  }
}

@media screen and (max-width: 767px) {
  .accordion-button {
    font-size: 16px;
  }
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button.collapsed {
  border-bottom-width: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.accordion-button:not(.collapsed) {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.accordion-button:not(.collapsed) span {
  background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.accordion-button:not(.collapsed)::after {
  font-weight: 700;
  font-size: 16px;
  content: "-";
  background-image: none;
  transform: rotate(0deg);
  color: #feb423;
}

.accordion-button::after {
  background-image: none;
  font-weight: 700;
  font-size: 16px;
  content: "+";
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-text-fill-color: currentColor;
  background-color: white;
  box-shadow: 0px 5px 8px 0px rgba(39, 66, 161, 0.03);
  width: 28px;
  height: 28px;
  z-index: 1;
  border-radius: 50%;
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #8189b5;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(2, 18, 106, 0.25);
}

.accordion2 .accordion-button {
  padding: 18.5px 40px;
}

.accordion2 .accordion-button:not(.collapsed)::after {
  background: linear-gradient(to right, #fc7318, #fe8415, #fe9515, #fea51a, #feb423);
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #fc7318 0%, #feb423 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #f7faff inset;
  color: #fc7418;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background: #f7faff;
  margin-bottom: 10px;
  border-radius: 15px;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-width: 0px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-width: 0px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.accordion-collapse {
  border: solid rgba(0, 0, 0, 0.125);
  border-width: 0 0px;
}

.accordion-body {
  padding: 19px 40px;
  padding-top: 0px;
}

.accordion-flush .accordion-button {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item:first-of-type .accordion-button {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion-flush .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

/*--------------------------------------
/*   [ 16. testimonial CSS ] 
----------------------------------------*/
.testimonial-section {
  padding-bottom: 140px;
}

@media screen and (max-width: 1199px) {
  .testimonial-section {
    padding-bottom: 100px;
  }
}

.testimonial-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  padding: 0px 70px 85px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-content {
    padding: 0px 120px 85px;
  }
}

@media screen and (max-width: 767px) {
  .testimonial-content {
    padding: 0px 0px 5px;
  }
}

.testimonial-content .shape {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: block;
  margin: 0 auto;
  z-index: -2;
}

.testimonial-content p {
  font-size: 20px;
  line-height: 1.8;
  margin-bottom: 40px;
}

@media screen and (max-width: 991px) {
  .testimonial-content p {
    font-size: 16px;
  }
}

.testimonial-profile {
  margin-bottom: 75px;
}

.quote {
  font-weight: 700;
  line-height: 1;
  font-size: 190px;
  background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  display: block;
  margin: 0 auto;
  z-index: 1;
}

.sub-title {
  color: #02126a;
  font-size: 25px;
  line-height: 0.8;
}

.designation {
  color: #fc7418;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  line-height: 1.8;
  margin-top: 5px;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .clients {
    display: none;
  }
}

.clients .client {
  position: absolute;
  z-index: 4;
}

.clients .client:nth-child(1) {
  top: 0;
  left: 0;
  animation: zoomin 0.9s infinite alternate;
}

@media screen and (max-width: 1499px) {
  .clients .client:nth-child(1) {
    left: 20px;
  }
}

.clients .client:nth-child(2) {
  top: 190px;
  left: -115px;
  animation: zoomin 1.3s infinite alternate;
}

@media screen and (max-width: 1499px) {
  .clients .client:nth-child(2) {
    left: 30px;
  }
}

.clients .client:nth-child(3) {
  left: 0;
  bottom: 70px;
  animation: zoomin 1.6s infinite alternate;
}

@media screen and (max-width: 1499px) {
  .clients .client:nth-child(3) {
    left: 20px;
  }
}

.clients .client:nth-child(4) {
  top: 40px;
  right: 0;
  animation: zoomin 1.9s infinite alternate;
}

@media screen and (max-width: 1499px) {
  .clients .client:nth-child(4) {
    right: 20px;
  }
}

.clients .client:nth-child(5) {
  right: -85px;
  bottom: 135px;
  animation: zoomin 2s infinite alternate;
}

@media screen and (max-width: 1499px) {
  .clients .client:nth-child(5) {
    right: 20px;
  }
}

.clients .client:nth-child(6) {
  right: 220px;
  bottom: 55px;
  animation: zoomin 2.3s infinite alternate;
}

@-webkit-keyframes zoomin {
  0% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(0.8);
  }
}

@keyframes zoomin {
  0% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(0.8);
  }
}

/*--------------------------------------
/*   [ 17. blog CSS ] 
----------------------------------------*/
.blog-section {
  padding: 145px 0px;
  background: #f7faff;
  background-image: url("../images/blog/bg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 1199px) {
  .blog-section {
    padding: 100px 0px;
  }
}

.blog-card {
  margin-top: 30px;
  background-color: #fff;
  padding: 30px;
  border-radius: 15px;
}

@media screen and (max-width: 991px) {
  .blog-card {
    margin-top: 0px;
  }
}

.blog-card:hover {
  background-image: -moz-linear-gradient(0deg, #02126a 0%, #2647c8 100%);
  background-image: -webkit-linear-gradient(0deg, #02126a 0%, #2647c8 100%);
  background-image: -ms-linear-gradient(0deg, #02126a 0%, #2647c8 100%);
}

.blog-card .thumb {
  background-color: #f7faff;
  overflow: hidden;
  margin-bottom: 20px;
  border-radius: 15px;
}

.blog-card .thumb a {
  display: block;
}

.blog-card .thumb a img {
  transition: all 0.3s;
}

.blog-card:hover .thumb img {
  transform: scale(1.05);
}

.blog-card .content p {
  color: #747785;
  font-weight: 700;
  line-height: 2.5;
  margin-bottom: 15px;
  font-size: 14px;
}

.blog-card .content p span {
  background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blog-card .content .title {
  font-weight: 700;
  color: #02126a;
  line-height: 1.59;
  font-size: 22px;
}

@media screen and (max-width: 1199px) {
  .blog-card .content .title {
    font-size: 18px;
  }
}

.blog-card .content .title a {
  color: inherit;
}

.blog-card .content .title a:hover {
  color: #fc7418;
}

.blog-card:hover .content p {
  color: #fff;
}

.blog-card:hover .content .title {
  background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blog-card:hover .content .title a:hover {
  color: inherit;
}

.blog-meta-cards {
  margin-bottom: -30px;
}

.blog-meta-card {
  background-color: #fff;
  display: flex;
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 15px;
  transition: all 0.3s;
}

@media screen and (max-width: 575px) {
  .blog-meta-card {
    flex-direction: column;
  }
}

.blog-meta-card:hover {
  background-image: -moz-linear-gradient(0deg, #02126a 0%, #2647c8 100%);
  background-image: -webkit-linear-gradient(0deg, #02126a 0%, #2647c8 100%);
  background-image: -ms-linear-gradient(0deg, #02126a 0%, #2647c8 100%);
}

.blog-meta-card:hover .content p {
  color: #fff;
}

.blog-meta-card:hover .content .title {
  background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blog-meta-card:hover .content .title a {
  color: inherit;
}

.blog-meta-card:hover .content .title a:hover {
  background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blog-meta-card .thumb {
  margin-right: 30px;
  border-radius: 15px;
  overflow: hidden;
}

@media screen and (max-width: 575px) {
  .blog-meta-card .thumb {
    margin-bottom: 30px;
  }
}

.blog-meta-card .content {
  flex: 1;
}

.blog-meta-card .content p {
  color: #747785;
  font-weight: 700;
  line-height: 2.5;
  margin-bottom: 15px;
  font-size: 14px;
}

.blog-meta-card .content p span {
  background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blog-meta-card .content .title {
  font-weight: 700;
  color: #02126a;
  line-height: 1.59;
  font-size: 21px;
}

@media screen and (max-width: 1199px) {
  .blog-meta-card .content .title {
    font-size: 18px;
  }
}

.blog-meta-card .content .title a {
  color: inherit;
}

.blog-meta-card .content .title a:hover {
  color: #fc7418;
}

.blog-grid-card {
  background-color: #f7faff;
  overflow: hidden;
  border-radius: 15px;
}

@media screen and (max-width: 767px) {
  .blog-grid-card {
    padding-top: 30px;
  }
}

.blog-grid-card:hover img {
  transform: scale(1.05);
}

.blog-grid-thumb {
  display: block;
  border-radius: 15px;
  overflow: hidden;
  text-align: center;
}

.blog-grid-thumb img {
  border-radius: 15px;
  transition: all 0.3s linear;
}

.blog-grid-content ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 1.25rem;
}

.blog-grid-content ul li {
  text-transform: capitalize;
  display: inline-block;
  font-size: 14px;
}

.blog-grid-content {
  padding: 30px 30px;
  max-width: 390px;
  margin: 0 auto;
}

.blog-grid-content .title {
  text-transform: capitalize;
  font-weight: 700;
  color: #02126a;
  line-height: 1.5;
  font-size: 20px;
}

.blog-grid-content .title:hover {
  color: #fc7418;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-grid-content .title {
    font-size: 18px;
  }
}

.blog-grid-content .title a {
  color: inherit;
}

.blog-grid-content .btn {
  text-transform: capitalize;
}

.blog-grid-content .link-share {
  font-weight: 400;
  color: #596190;
}

.blog-grid-content .link-share:hover {
  color: #fc7418;
}

.blog-grid-content .link-share a {
  color: inherit;
}

.blog-grid-content .comments {
  font-weight: 400;
  color: #596190;
}

.blog-grid-content .comments:hover {
  color: #fc7418;
}

.blog-grid-content .comments a {
  color: inherit;
}

.blog-grid-content .date {
  font-weight: 400;
  color: #596190;
}

.blog-grid-content .date i {
  margin-right: 5px;
}

.pagination {
  justify-content: center;
}

.pagination .page-item {
  margin: 0 5px;
}

.pagination .page-item.active .page-link {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: transparent;
}

.pagination .page-item .page-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  color: #fff;
  font-size: 20px;
  border-radius: 50%;
  background: -webkit-gradient(linear, left top, right top, from(#fc7318), color-stop(#fe8415), color-stop(#fe9515), color-stop(#fea51a), to(#feb423));
  background: -webkit-linear-gradient(left, #fc7318, #fe8415, #fe9515, #fea51a, #feb423);
  background: -o-linear-gradient(left, #fc7318, #fe8415, #fe9515, #fea51a, #feb423);
  background: linear-gradient(to right, #fc7318, #fe8415, #fe9515, #fea51a, #feb423);
  background-image: linear-gradient(to right, #fc7318, #fe8415, #fe9515, #fea51a, #feb423);
  background-origin: padding-box;
  background-clip: border-box;
  border: solid 2px transparent;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), -webkit-linear-gradient(349deg, #fc7318 0%, #feb423 100%);
  background-image: -o-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), -o-linear-gradient(349deg, #fc7318 0%, #feb423 100%);
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #fc7318 0%, #feb423 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  -webkit-box-shadow: 2px 1000px 1px #f7faff inset;
  box-shadow: 2px 1000px 1px #f7faff inset;
  color: #fc7418;
}

.pagination .page-item .page-link:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: transparent;
}

/*--------------------------------------
/*   [ 18. footer CSS ] 
----------------------------------------*/
.footer-section {
  position: relative;
}

.footer-section .shape {
  position: absolute;
  right: 80px;
  bottom: 106px;
}

.footer-section .footer-bg-shape {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
  z-index: -1;
}

@media screen and (max-width: 1199px) {
  .footer-section .footer-bg-shape {
    display: none;
  }
}

.news-letter-form {
  max-width: 970px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .news-letter-form {
    text-align: center;
  }
}

.news-letter-form .form-control {
  background-color: #08186e;
  height: 75px;
  border: 1px solid #1b2979;
  padding-left: 30px;
  padding-right: 200px;
  border-radius: 8px;
  font-size: 14px;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .news-letter-form .form-control {
    height: 65px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.news-letter-form .form-control::placeholder {
  color: #fff;
}

@media screen and (min-width: 576px) {
  .news-letter-form .btn {
    position: absolute;
    top: 9px;
    right: 10px;
  }
}

@media screen and (max-width: 575px) {
  .news-letter-form .btn {
    margin-top: 50px;
  }
}

.news-letter-form .btn.btn-warning {
  padding: 20px 25px;
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .news-letter-form .btn.btn-warning {
    padding: 18px 20px;
    font-size: 14px;
  }
}

.path-shape {
  position: absolute;
  top: 165px;
  left: 40px;
  z-index: 1;
  animation: move 1s infinite alternate;
}

@media screen and (max-width: 1199px) {
  .path-shape {
    top: 50px;
    left: 15px;
    max-width: 90%;
  }
}

.footer-row {
  display: flex;
}

@media screen and (max-width: 1199px) {
  .footer-row {
    flex-wrap: wrap;
    margin: -30px 0px;
  }
}

.footer-row .footer-col {
  border-right: 1px solid #273581;
}

@media screen and (min-width: 1200px) {
  .footer-row .footer-col {
    flex: 1 0;
  }
}

@media screen and (max-width: 1199px) {
  .footer-row .footer-col {
    width: 50%;
  }
}

@media screen and (max-width: 575px) {
  .footer-row .footer-col {
    width: 100%;
    margin-top: 0px !important;
  }
}

@media screen and (max-width: 1199px) {
  .footer-row .footer-col {
    margin: 30px 0px;
  }
}

.footer-row .footer-col:last-child {
  border-right: 0px;
}

@media screen and (max-width: 1199px) {
  .footer-row .footer-col:last-child {
    margin-top: 0px;
  }
}

@media screen and (max-width: 1199px) {
  .footer-row .footer-col:last-child .footer-widget {
    padding-bottom: 0px;
  }
}

@media screen and (min-width: 1200px) {
  .footer-row .footer-col:nth-child(1) {
    max-width: 390px;
    flex: 1 0 100%;
  }
}

@media screen and (max-width: 767px) {
  .footer-row .footer-col:nth-child(1) .footer-widget {
    padding: 15px 15px 15px;
  }
}

@media screen and (max-width: 1199px) {
  .footer-row .footer-col:nth-child(3) {
    margin-top: 0px;
  }
}

@media screen and (max-width: 1199px) {
  .footer-row .footer-col {
    border-right: 0px;
  }
}

.footer-row .footer-col .footer-widget {
  padding: 50px 30px 70px 50px;
}

@media screen and (max-width: 1199px) {
  .footer-row .footer-col .footer-widget {
    padding: 30px 30px;
  }
}

@media screen and (max-width: 1199px) {
  .footer-row .footer-col .footer-widget {
    padding: 0px 30px 15px;
  }
}

@media screen and (max-width: 767px) {
  .footer-row .footer-col .footer-widget {
    padding: 0px 15px 15px;
  }
}

.footer-top {
  padding: 100px 0px;
}

.footer-top .container {
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 1199px) {
  .footer-top {
    background: #0a196f;
  }
}

.footer-card {
  background: #02126a;
  border-radius: 15px;
}

.footer-widget .footer-logo {
  display: block;
  margin-bottom: 40px;
}

.footer-widget p {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.687;
  color: #c6c9d8;
  font-size: 15px;
  margin-bottom: 35px;
}

.footer-widget .title {
  color: #fff;
  font-weight: 900;
  line-height: 1;
  font-size: 22px;
  position: relative;
  padding-bottom: 30px;
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .footer-widget .title {
    font-size: 18px;
  }
}

.footer-widget .title::before, .footer-widget .title::after {
  position: absolute;
  bottom: 0;
  content: "";
  height: 5px;
  border-radius: 5px;
}

.footer-widget .title::before {
  width: 30px;
  left: 0px;
  background-image: -moz-linear-gradient(0deg, #fc7318 0%, #feb423 100%);
  background-image: -webkit-linear-gradient(0deg, #fc7318 0%, #feb423 100%);
  background-image: -ms-linear-gradient(0deg, #fc7318 0%, #feb423 100%);
}

.footer-widget .title::after {
  background: #e6e9f5;
  width: 15px;
  left: 40px;
}

.adress li {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.687;
  color: #c6c9d8;
  font-size: 16px;
  padding: 10px 0px;
  border-bottom: 1px solid #273581;
}

.adress li:first-child {
  border-top: 1px solid #273581;
}

.adress li .icon {
  background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  display: inline-block;
  margin-right: 30px;
}

.adress li a {
  color: inherit;
}

.adress li a:hover {
  color: #fc7418;
}

.footer-link {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 2.533;
  color: #c6c9d8;
  font-size: 15px;
}

.footer-link i {
  margin-right: 20px;
}

.footer-social {
  display: flex;
  flex-wrap: wrap;
}

.footer-social-item:nth-child(2) .footer-social-link {
  border-left: 0px;
  border-right: 0px;
}

.footer-social-link {
  border: 1px solid #273581;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 15px;
}

.footer-social-link:hover {
  background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.copy-right-section {
  padding: 20px 0px;
  background: #111f73;
}

.copy-right-section P {
  text-align: center;
  color: #c7cbdf;
}

.copy-right-section P a {
  color: #fc7418;
}

.copy-right-section P i {
  background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.path-svg {
  max-width: 127px;
  max-height: 305px;
  position: absolute;
  right: 80px;
  bottom: 106px;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .path-svg {
    right: 10px;
    bottom: 90px;
  }
}

.cls-1 {
  isolation: isolate;
}

.cls-2 {
  mix-blend-mode: multiply;
  fill: url(#radial-gradient);
}

.cls-3 {
  fill: url(#linear-gradient);
}

.cls-4 {
  fill: url(#linear-gradient-2);
}

.cls-5 {
  fill: #e2e9fa;
}

.cls-6 {
  fill: #f0f4fc;
}

.cls-7 {
  fill: #6e8fe4;
}

.cls-8 {
  stroke: #2647c8;
  fill: none;
  stroke-width: 2;
  stroke-dasharray: 800;
  stroke-dashoffset: 800;
  animation: offset linear 5s forwards infinite;
}

@-webkit-keyframes offset {
  0% {
    stroke-dashoffset: 800;
  }
  80% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #2647c8;
  }
}

@-moz-keyframes offset {
  0% {
    stroke-dashoffset: 800;
  }
  80% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #2647c8;
  }
}

/*--------------------------------------
/*   [ 19. bread crumb CSS ] 
----------------------------------------*/
.bread-crumb-section {
  position: relative;
  padding: 120px 0px;
  background-image: url("../images/bread/bg.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bread-crumb-section .container {
  position: relative;
  z-index: 3;
}

@media screen and (max-width: 1199px) {
  .bread-crumb-section {
    font-size: 100px;
  }
}

@media screen and (max-width: 767px) {
  .bread-crumb-section {
    font-size: 80px;
  }
}

.bread-crumb-section .shape {
  z-index: 1;
}

.bread-crumb-section .shape1 {
  top: 0;
  left: 0;
}

.bread-crumb-section .shape2 {
  top: auto;
  bottom: 0;
  right: 0;
}

.bread-crumb-section .title {
  color: #fff;
  font-weight: 700;
  line-height: 1.545;
  font-size: 55px;
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .bread-crumb-section .title {
    font-size: 45px;
  }
}

@media screen and (max-width: 767px) {
  .bread-crumb-section .title {
    font-size: 35px;
  }
}

.breadcrumb {
  margin-bottom: 0;
}

.breadcrumb-item {
  font-weight: 700;
  font-size: 15px;
}

.breadcrumb-item a {
  color: #fff;
}

.breadcrumb-item a:hover {
  background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.breadcrumb-item + .breadcrumb-item::before {
  padding-right: 0.5rem;
  color: #fff;
  font-family: "IcoFont";
  content: var(--bs-breadcrumb-divider, "");
}

.breadcrumb-item.active span {
  background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*--------------------------------------
/*   [ 20. service page CSS ] 
----------------------------------------*/
.service-carousel-section {
  position: relative;
  padding-top: 150px;
  margin-bottom: 55px;
}

@media screen and (max-width: 1199px) {
  .service-carousel-section {
    padding-top: 100px;
  }
}

.service-carousel-content {
  background-color: #f7faff;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}

.service-carousel-content .service-img {
  border-radius: 15px;
}

.service-carousel .swiper-button-prev,
.service-carousel .swiper-button-next {
  width: 55px;
  height: 55px;
  color: #fff;
  font-size: 20px;
  border-radius: 50%;
}

.service-carousel .swiper-button-prev {
  left: 50px;
  background: linear-gradient(to right, #fc7318, #fe8415, #fe9515, #fea51a, #feb423);
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #fc7318 0%, #feb423 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #f7faff inset;
  color: #fc7418;
}

.service-carousel .swiper-button-prev:hover {
  box-shadow: none;
  color: #fff;
  border-color: transparent;
}

.service-carousel .swiper-button-next {
  right: 50px;
  background: linear-gradient(to right, #fc7318, #fe8415, #fe9515, #fea51a, #feb423);
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #fc7318 0%, #feb423 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #f7faff inset;
  color: #fc7418;
}

.service-carousel .swiper-button-next:hover {
  box-shadow: none;
  color: #fff;
  border-color: transparent;
}

.service-carousel .swiper-button-prev::after,
.service-carousel .swiper-container-rtl .swiper-button-next::after {
  display: none;
}

.service-carousel .swiper-button-next::after,
.service-carousel .swiper-container-rtl .swiper-button-next::after {
  display: none;
}

.service-details-section {
  padding-bottom: 150px;
}

@media screen and (max-width: 1199px) {
  .service-details-section {
    padding-bottom: 100px;
  }
}

.service-details .title {
  color: #02126a;
  line-height: 1.857;
  font-size: 35px;
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .service-details .title {
    font-size: 30px;
  }
}

@media screen and (max-width: 767px) {
  .service-details .title {
    font-size: 25px;
  }
}

.service-details p {
  margin-bottom: 30px;
}

@media screen and (max-width: 991px) {
  .service-details p {
    margin-bottom: 20px;
  }
}

.service-details .experience {
  margin-bottom: 30px;
}

@media screen and (max-width: 991px) {
  .service-details img {
    display: block;
    margin: 0 auto 30px;
  }
}

.next-prev-section {
  margin-top: 30px;
  padding: 20px 25px 20px;
  background-color: #f7faff;
  border-radius: 12px;
}

@media screen and (min-width: 1200px) {
  .next-prev-section {
    padding: 20px 25px 0px;
  }
}

.service-prev .img {
  margin-right: 30px;
}

.service-next .img {
  margin-left: 30px;
}

.service-next .conten {
  text-align: right;
}

.service-media {
  display: flex;
}

.service-media .img {
  background-color: white;
  padding: 15px 5px;
  box-shadow: 0px 22px 35px 0px rgba(2, 18, 106, 0.08);
  border-radius: 12px;
}

@media screen and (min-width: 1200px) {
  .service-media .img {
    margin-bottom: -15px;
  }
}

.service-media .conten {
  flex: 1;
}

.service-media .conten .title {
  font-weight: 700;
  color: #02126a;
  line-height: 1.35;
  margin-bottom: 5px;
  font-size: 20px;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .service-media .conten .title {
    font-size: 18px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .service-media .conten .title {
    font-size: 15px;
  }
}

@media screen and (max-width: 767px) {
  .service-media .conten .title {
    font-size: 16px;
  }
}

@media screen and (max-width: 575px) {
  .service-media .conten .title {
    font-size: 13px;
  }
}

.service-media .conten .link {
  background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 400;
  line-height: 1.928;
  font-size: 14px;
}

.progress {
  background-color: #f7faff;
  height: 6px;
  border-radius: 0;
  overflow: visible;
  margin-bottom: 12px;
}

.progress-bar {
  position: relative;
  justify-content: end;
  text-align: right;
  overflow: visible;
  width: 0;
  background-image: -moz-linear-gradient(0deg, #fc7318 0%, #feb423 100%);
  background-image: -webkit-linear-gradient(0deg, #fc7318 0%, #feb423 100%);
  background-image: -ms-linear-gradient(0deg, #fc7318 0%, #feb423 100%);
  transition: width 1.5s ease-in-out;
  color: #596190;
  line-height: 1.928;
  text-transform: capitalize;
  margin-bottom: 0;
  font-size: 14px;
}

.progress-bar::before {
  position: absolute;
  top: -5px;
  right: 0;
  width: 3px;
  height: 15px;
  content: "";
  background-color: #feb423;
}

.progress-bar span {
  display: block;
  margin-right: -15px;
  position: absolute;
  right: 0;
  top: 20px;
  transition: 1s ease-in-out;
  opacity: 0;
  transform: scale(0.5);
}

.progress-bar span.opacity {
  opacity: 1;
  transform: scale(1);
}

.ht-progress .ht-progress-item {
  margin-bottom: 20px;
}

.ht-progress .ht-progress-item .title {
  color: #596190;
  line-height: 1.928;
  text-transform: capitalize;
  margin-bottom: 0;
  font-size: 14px;
}

/*--------------------------------------
/*   [ 21. case details CSS ] 
----------------------------------------*/
.case-details-carousel .swiper-slide.swiper-slide-active .case-details-carousel-content {
  animation: fadeInRight 0.5s linear;
}

.case-details-carousel .swiper-slide.swiper-slide-active .case-details-card {
  text-align: center;
  animation: fadeInRight 0.5s linear;
}

.case-details-carousel-content {
  position: relative;
  text-align: center;
  overflow: hidden;
  border-radius: 15px;
}

.case-details-carousel-content .case-details-cshape {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  border-radius: 15px;
  z-index: 1;
}

.case-details-carousel-content .case-details-img {
  position: relative;
  z-index: 2;
}

.case-details-carousel .swiper-button-prev,
.case-details-carousel .swiper-button-next {
  width: 55px;
  height: 55px;
  color: #fff;
  font-size: 20px;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
}

.case-details-carousel:hover .swiper-button-prev,
.case-details-carousel:hover .swiper-button-next {
  opacity: 1;
  visibility: visible;
}

.case-details-carousel .swiper-button-prev {
  left: auto;
  right: 95px;
  top: auto;
  bottom: 65px;
  background: linear-gradient(to right, #fc7318, #fe8415, #fe9515, #fea51a, #feb423);
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #fc7318 0%, #feb423 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #f7faff inset;
  color: #fc7418;
}

.case-details-carousel .swiper-button-prev:hover {
  box-shadow: none;
  color: #fff;
  border-color: transparent;
}

.case-details-carousel .swiper-button-next {
  right: 30px;
  top: auto;
  bottom: 65px;
  background: linear-gradient(to right, #fc7318, #fe8415, #fe9515, #fea51a, #feb423);
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #fc7318 0%, #feb423 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #f7faff inset;
  color: #fc7418;
}

.case-details-carousel .swiper-button-next:hover {
  box-shadow: none;
  color: #fff;
  border-color: transparent;
}

.case-details-carousel .swiper-button-prev::after,
.case-details-carousel .swiper-container-rtl .swiper-button-next::after {
  display: none;
}

.case-details-carousel .swiper-button-next::after,
.case-details-carousel .swiper-container-rtl .swiper-button-next::after {
  display: none;
}

.case-details-card {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}

.case-img {
  position: relative;
  z-index: 2;
  display: block;
  margin: 0 auto;
}

.case-details2-img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.case-details-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: -20px;
  margin-bottom: -20px;
}

@media screen and (max-width: 991px) {
  .case-details-row {
    justify-content: start;
  }
}

.case-details-col {
  padding-right: 20px;
  padding-bottom: 20px;
}

@media screen and (min-width: 501px) and (max-width: 991px) {
  .case-details-col {
    max-width: 33%;
    flex: 0 33%;
  }
}

@media screen and (max-width: 500px) {
  .case-details-col {
    max-width: 50%;
    flex: 0 50%;
  }
}

.case-clients-card {
  background-color: #f7faff;
  padding: 70px;
  margin-bottom: 65px;
  border-radius: 15px;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .case-clients-card {
    padding: 50px;
  }
}

@media screen and (max-width: 991px) {
  .case-clients-card {
    padding: 30px;
  }
}

@media screen and (max-width: 767px) {
  .case-clients-card {
    padding: 15px;
  }
}

@media screen and (max-width: 991px) {
  .case-clients-card {
    margin-bottom: 35px;
  }
}

.case-clients-card-inner {
  background-color: #fff;
  padding: 60px 50px;
  border-radius: 15px;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .case-clients-card-inner {
    padding: 40px;
  }
}

@media screen and (max-width: 991px) {
  .case-clients-card-inner {
    padding: 30px;
  }
}

@media screen and (max-width: 767px) {
  .case-clients-card-inner {
    padding: 15px;
  }
}

.case-clients-card-inner .title {
  color: #02126a;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 20px;
  font-size: 20px;
}

@media screen and (max-width: 767px) {
  .case-clients-card-inner .title {
    font-size: 18px;
  }
}

.case-clients-card-inner .clients-hr {
  background-image: -moz-linear-gradient(0deg, #fc7318 0%, #feb423 100%);
  background-image: -webkit-linear-gradient(0deg, #fc7318 0%, #feb423 100%);
  background-image: -ms-linear-gradient(0deg, #fc7318 0%, #feb423 100%);
  position: relative;
  display: block;
  width: 20px;
  height: 4px;
  margin-bottom: 15px;
  border-radius: 5px;
}

.case-clients-card-inner .clients-hr::before {
  position: absolute;
  left: 30px;
  top: 0;
  background-color: #ecf1fa;
  width: 45px;
  height: 100%;
  content: "";
  border-radius: inherit;
}

.case-clients-card-inner p {
  font-weight: 400;
  line-height: 1.4;
  font-size: 15px;
  color: #596190;
}

@media screen and (max-width: 767px) {
  .case-clients-card-inner p {
    font-size: 12px;
  }
}

.share {
  display: inline-block;
  color: #02126a;
  font-weight: 700;
  line-height: 1;
  margin-right: 10px;
  font-size: 15px;
}

.share-social-links .social-link-item + .social-link-item {
  margin-left: 10px;
}

.share-social-links .social-link-item .social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #f7faff;
  border: 1px solid #ecedf2;
  color: #02126a;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 40px;
}

.share-social-links .social-link-item .social-link:hover i {
  background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.share-social-links2 .social-link-item .social-link {
  background-color: #ffffff;
  border-color: #ecedf2;
}

/*--------------------------------------
/*   [ 22. comment form CSS ] 
----------------------------------------*/
.comment-form-space1 {
  margin-top: 75px;
}

.comment-card {
  background-color: #f7faff;
  padding: 55px 70px 70px;
  border-radius: 15px;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .comment-card {
    padding: 35px 50px 50px;
  }
}

@media screen and (max-width: 991px) {
  .comment-card {
    padding: 35px 25px 50px;
  }
}

.comment-card .title {
  color: #02126a;
  line-height: 1.857;
  font-size: 35px;
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .comment-card .title {
    font-size: 30px;
  }
}

@media screen and (max-width: 767px) {
  .comment-card .title {
    font-size: 25px;
  }
}

.comment-hr {
  position: relative;
  display: block;
  margin-left: 25px;
  margin-bottom: 40px;
  background-image: -moz-linear-gradient(0deg, #fc7318 0%, #feb423 100%);
  background-image: -webkit-linear-gradient(0deg, #fc7318 0%, #feb423 100%);
  background-image: -ms-linear-gradient(0deg, #fc7318 0%, #feb423 100%);
  width: 30px;
  height: 5px;
  border-radius: 5px;
}

.comment-hr::before {
  width: 15px;
  height: inherit;
  border-radius: inherit;
  background: #e6e9f5;
  content: "";
  position: absolute;
  left: -25px;
  top: 0;
}

.comment-hr::after {
  width: 15px;
  height: inherit;
  border-radius: inherit;
  background: #e6e9f5;
  content: "";
  position: absolute;
  right: -30px;
  top: 0;
}

.comment-form .form-control {
  background-color: white;
  height: 60px;
  padding: 15px 30px;
  color: #596190;
  margin-bottom: 20px;
  border: 0px;
  font-size: 15px;
  border-radius: 10px;
}

.comment-form .form-control::placeholder {
  color: #596190;
}

.comment-form .form-control.textarea-control {
  height: 170px;
  margin-bottom: 30px;
  resize: none;
}

.service-dots strong {
  font-weight: 700;
  color: #02126a;
  font-size: 14px;
}

.contact-form-card .selectric,
.comment-card .selectric {
  background: #fff;
  height: 60px;
  padding: 15px 30px;
  color: #596190;
  margin-bottom: 20px;
  border: 0px;
  font-size: 15px;
  border-radius: 10px;
}

.contact-form-card .selectric::placeholder,
.comment-card .selectric::placeholder {
  color: #596190;
}

.contact-form-card .selectric .label,
.comment-card .selectric .label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 38px 0 10px;
  font-size: 15px;
  line-height: 38px;
  color: #596190;
  height: 38px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectric {
  border: 0px;
  background: transparent;
}

.selectric .label {
  color: #fff;
  font-size: 15px;
}

.selectric .button {
  background: transparent;
}

.selectric .button::after {
  top: 30px;
  width: 15px;
  height: 15px;
  content: " ";
  background-image: url("../images/icon/arrow-down.png");
  background-repeat: no-repeat;
  border: 0px solid transparent;
}

.selectric-items {
  background: #fff;
  border: 0px;
  box-shadow: 0px 22px 35px 0px rgba(2, 18, 106, 0.08);
}

.selectric-items li.highlighted {
  background-image: linear-gradient(to right, #fc7318, #fe8415, #fe9515, #fea51a, #feb423);
  color: #fff;
  font-size: 14px;
}

.selectric-items ul,
.selectric-items li {
  font-size: 14px;
  color: #02126a;
}

.selectric-items li:hover {
  background: #02126a;
  color: #fff;
}

@media screen and (min-width: 992px) {
  .comment-form-img2 {
    position: relative;
    right: -20px;
    margin-top: 10px;
  }
}

@media screen and (min-width: 1200px) {
  .comment-form-img2 {
    position: relative;
    right: -40px;
    margin-top: 0px;
    transform: scale(1.05);
  }
}

/*--------------------------------------
/*   [ 23. blog details CSS ] 
----------------------------------------*/
.sidebar {
  margin-bottom: -60px;
}

.sidebar-widget {
  background-color: #f7faff;
  padding: 30px;
  margin-bottom: 60px;
  border-radius: 15px;
}

@media screen and (min-width: 1200px) {
  .sidebar-widget {
    padding: 50px;
  }
}

.sidebar-widget .title {
  text-transform: capitalize;
  font-weight: 700;
  color: #02126a;
  line-height: 1;
  margin-bottom: 30px;
  font-size: 30px;
}

.profile-card {
  text-align: center;
}

@media screen and (min-width: 1200px) {
  .profile-card {
    padding-bottom: 60px;
  }
}

.profile-card .profile {
  margin-bottom: 35px;
}

.profile-card .title {
  line-height: 1.25;
  font-size: 20px;
  margin-bottom: 10px;
}

.profile-card p {
  font-weight: 700;
  color: #fc7418;
  text-transform: uppercase;
  font-size: 13px;
  margin-bottom: 35px;
}

.profile-social-links {
  text-align: center;
  justify-content: center;
}

.profile-social-links .social-link-item + .social-link-item {
  margin-left: 10px;
}

.profile-social-links .social-link-item .social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: white;
  box-shadow: 0px 15px 30px 0px rgba(2, 18, 106, 0.05);
  color: #02126a;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
}

.profile-social-links .social-link-item .social-link:hover {
  background-image: -moz-linear-gradient(0deg, #fc7318 0%, #feb423 100%);
  background-image: -webkit-linear-gradient(0deg, #fc7318 0%, #feb423 100%);
  background-image: -ms-linear-gradient(0deg, #fc7318 0%, #feb423 100%);
  color: #fff;
}

.search-card .form {
  position: relative;
}

.search-card .form-control {
  background-color: white;
  height: 55px;
  padding: 20px;
  font-size: 15px;
  border: 0px;
  border-radius: 10px;
}

.search-card .form-control::placeholder {
  color: #596190;
}

.search-card .search-button {
  border-radius: 10px;
  background-image: linear-gradient(to right, #fc7318, #fe8415, #fe9515, #fea51a, #feb423);
  position: absolute;
  top: 0;
  right: 0;
  width: 55px;
  height: 55px;
  color: #fff;
}

.search-card .search-button:hover {
  background-image: linear-gradient(to left, #fc7318, #fe8415, #fe9515, #fea51a, #feb423);
}

.list-group {
  margin-bottom: -10px;
}

.list-group-item {
  font-weight: 400;
  background-color: white;
  border: 0px;
  padding: 14px 20px;
  margin-bottom: 10px;
  border-radius: 10px !important;
}

.list-group-item a {
  color: #596190;
}

.list-group-item a i {
  margin-right: 10px;
}

.list-group-item span {
  color: #596190;
}

.list-group-item:hover a {
  background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.list-group-item:hover span {
  background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tag-card ul {
  margin-left: -10px;
  margin-bottom: -10px;
}

.tag-item {
  display: inline-block;
}

.tag-link {
  color: #02126a;
  display: inline-block;
  padding: 9px 20px;
  margin-bottom: 10px;
  margin-left: 10px;
  background-color: white;
  border-radius: 5px;
}

.tag-link:hover {
  background-image: linear-gradient(to left, #fc7318, #fe8415, #fe9515, #fea51a, #feb423);
  color: #fff;
}

.Bg {
  position: absolute;
  left: 425px;
  top: 2636px;
  width: 115px;
  height: 45px;
  z-index: 345;
}

.blog-details-thumb {
  background-color: #f7faff;
  overflow: hidden;
  margin-bottom: 60px;
  border-radius: 15px;
}

.blog-details-thumb img {
  border-radius: 15px;
}

.sidebar .about-banner {
  margin-bottom: 60px;
}

@media screen and (min-width: 1200px) {
  .sidebar .about-banner {
    padding: 70px 66px;
  }
}

.social-share-card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #f7faff;
  padding: 30px;
  margin-bottom: 45px;
  border-radius: 15px;
}

@media screen and (max-width: 575px) {
  .social-share-card {
    padding: 30px 20px;
  }
}

.social-share-card > ul {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -15px;
  margin-left: -15px;
}

@media screen and (min-width: 1200px) {
  .social-share-wrap {
    margin-left: 25px;
  }
}

@media screen and (max-width: 1199px) {
  .social-share-wrap .share {
    margin-top: 15px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .social-share-wrap {
    margin-left: 25px;
  }
  .social-share-wrap .share {
    margin-top: 0px;
  }
}

.social-share-item {
  margin-bottom: 15px;
  margin-left: 15px;
  text-align: center;
  padding: 20px;
  background-color: white;
  border-radius: 12px;
}

@media screen and (max-width: 559px) {
  .social-share-item {
    margin-left: 10px;
  }
}

@media screen and (max-width: 767px) {
  .social-share-item {
    padding: 15px;
  }
}

@media screen and (max-width: 559px) {
  .social-share-item {
    padding: 12px;
  }
}

.social-share-item i {
  display: block;
  color: #02126a;
  font-size: 20px;
  margin-bottom: 10px;
}

.social-share-item span {
  display: block;
  font-weight: 400;
  color: #596190;
  font-size: 15px;
}

@media screen and (max-width: 559px) {
  .social-share-item span {
    font-size: 13px;
  }
}

.flex-one {
  flex: 1;
}

.service-dots2 {
  font-size: 14px;
}

.blog-comment {
  margin-top: 60px;
  padding-bottom: 5px;
}

.blog-comment .title {
  font-weight: 700;
  line-height: 1.333;
  color: #02126a;
  margin-bottom: 45px;
  font-size: 30px;
}

@media screen and (max-width: 767px) {
  .blog-comment .title {
    font-size: 25px;
  }
}

.blog-comment-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 55px;
}

@media screen and (max-width: 575px) {
  .blog-comment-list {
    flex-direction: column;
  }
}

.blog-comment-list img {
  align-self: flex-start;
  margin-right: 30px;
  border-radius: 50px;
}

@media screen and (max-width: 575px) {
  .blog-comment-list img {
    margin-bottom: 30px;
  }
}

.blog-comment-list .content .sub-title {
  font-weight: 700;
  line-height: 1.481;
  color: #02126a;
  font-size: 20px;
}

@media screen and (min-width: 1200px) {
  .blog-comment-list .content .sub-title {
    font-size: 27px;
  }
}

.blog-comment-list .content .meta-date {
  font-weight: 700;
  text-transform: uppercase;
  color: #fc7518;
  font-size: 13px;
}

.blog-comment-list .content p {
  margin-bottom: 30px;
}

.blog-comment-list .content .btn-custom-outline {
  border: 2px solid #dde6f4;
  background-color: rgba(255, 255, 255, 0);
  padding: 15px 35px;
  border-radius: 50px;
}

.blog-comment-list .content .btn-custom-outline:hover {
  border-color: #fc7418;
}

.blog-comment-list .content .btn-custom-outline span {
  background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.next-prev-section2 {
  padding: 20px 20px !important;
}

/*--------------------------------------
/*   [ 24. contact form CSS ] 
----------------------------------------*/
.contact-form-card {
  background-color: #f7faff;
  padding: 30px;
  border-radius: 15px;
}

@media screen and (min-width: 1200px) {
  .contact-form-card {
    padding: 80px;
  }
}

.contact-form-card .title {
  color: #02126a;
  line-height: 1.857;
  font-size: 35px;
}

@media screen and (max-width: 767px) {
  .contact-form-card .title {
    font-size: 30px;
  }
}

.contact-media {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 575px) {
  .contact-media {
    flex-direction: column;
    align-items: flex-start;
  }
}

.contact-media .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  width: 65px;
  height: 65px;
  border: 1px solid #eef0f8;
  border-radius: 50%;
  background-color: #f7faff;
}

@media screen and (min-width: 1200px) {
  .contact-media .icon {
    width: 74px;
    height: 74px;
  }
}

@media screen and (max-width: 575px) {
  .contact-media .icon {
    margin-bottom: 20px;
  }
}

.contact-media .icon span {
  font-size: 30px;
  background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contact-media .content {
  flex: 1 0 0;
}

.contact-media .content .text {
  line-height: 1;
  display: block;
  margin-bottom: 15px;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 15px;
  background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contact-media .content .number {
  line-height: 1;
  font-weight: 700;
  color: #02126a;
  font-size: 20px;
}

@media screen and (min-width: 1200px) {
  .contact-media .content .number {
    font-size: 22px;
  }
}

.contact-media .content a.number:hover {
  background: linear-gradient(to right, #fc7318 0%, #feb423 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contact-media-list {
  background-color: white;
  padding: 25px 30px;
  border-radius: 15px;
}

@media screen and (min-width: 1200px) {
  .contact-media-list {
    padding: 25px 40px;
  }
}

.contact-media-list + .contact-media-list {
  margin-top: 20px;
}

.google-map {
  width: 100%;
  height: 350px;
  margin-top: 50px;
}

@media screen and (min-width: 992px) {
  .google-map {
    height: 500px;
    margin-top: 70px;
  }
}

.success {
  margin-top: 15px;
  color: green;
}

.error {
  margin-top: 15px;
  color: red;
}

/*--------------------------------------
/*   [ 25. page not found CSS ] 
----------------------------------------*/
.page-not-found-section {
  background-image: url("../images/page-not-found/shape-bg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.page-not-found {
  margin: -15px;
  text-align: center;
}

.page-not-found-item {
  position: relative;
  display: inline-block;
  margin: 15px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .page-not-found img {
    max-width: 200px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .page-not-found img {
    max-width: 150px;
  }
}

@media screen and (max-width: 575px) {
  .page-not-found img {
    max-width: 150px;
  }
}

.page-not-found .number {
  font-weight: 900;
  position: absolute;
  line-height: 1.545;
  color: #fff;
  font-size: 198px;
  display: block;
  margin: auto;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .page-not-found .number {
    font-size: 130px;
  }
}

@media screen and (min-width: 576px) and (max-width: 991px) {
  .page-not-found .number {
    font-size: 80px;
  }
}

@media screen and (max-width: 575px) {
  .page-not-found .number {
    font-size: 80px;
  }
}

.page-not-found-content {
  margin-top: 60px;
  text-align: center;
}

.page-not-found-content .title {
  font-weight: 700;
  line-height: 1.25;
  color: #02126a;
  font-size: 68px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .page-not-found-content .title {
    font-size: 45px;
  }
}

@media screen and (max-width: 575px) {
  .page-not-found-content .title {
    font-size: 45px;
  }
}

.page-not-found-content .title small, .page-not-found-content .title .small {
  font-size: 30px;
}

.page-not-found-content p {
  margin-bottom: 40px;
}

/*--------------------------------------
/*   [ The End ] 
----------------------------------------*/
